import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT}`;
import io from 'socket.io-client';

function connect() {
  try {
    const socket = io(`${service}`, {
      reconnect: true,
      reconnection: true,
      reconnectionDelay: 500,
    });
    return socket;
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @typedef {Object} AnalyticalChat
 * @property {string[]} [provider=['yunique', 'whatsapp', 'messenger', 'direct']]
 * @property {string[]} [yuniqueProviderIds]
 * @property {string} companyId
 * @property {Date} createdAtGte
 * @property {Date} createdAtLte
 * @property {number[]} [score]
 * @property {('terminated'|'completed'|'expired')[]} [status]
 * @property {('inbound'|'outbound')[]} [type]
 * @property {string[]} [createdBy]
 * @property {number} [waitTimeGte]
 * @property {number} [waitTimeLte]
 * @property {number} [totalTimeGte]
 * @property {number} [totalTimeLte]
 * @property {number} [attendanceTimeTimeGte]
 * @property {number} [attendanceTimeTimeLte]
 * @property {number[]} [tags]
 * @property {boolean} [finishedSilently]
 * @property {boolean} [transferred]
 * @property {('no_queue'|string[])} [queueIds]
 * @property {boolean} [anyTags=false]
 * @property {boolean} [noTags=false]
 * @property {string[]} [subjectIds]
 * @property {string} [protocol]
 * @property {number[]} [searchAfter]
 * @property {string} [token]
 * @property {string[]} [operatorsIds]
 */

/**
 *
 * @param {AnalyticalChat} params
 */
export async function getChatsAnalytical(params) {
  const { data } = await $http.get(`${service}/analytics/analytical`, { params });
  return data;
}

async function getLinkPreview(payload) {
  const { data } = await $http.post(`${service}/message/link-preview`, payload);
  return data;
}

async function getMessagesByRoom(query) {
  const { data } = await $http.get(`${service}/message`, { params: query });
  return data;
}

async function sendMetaMessage(payload) {
  const { data } = await $http.post(`${service}/meta/template-messages`, payload);
  return data;
}

async function sendMetaMessageV2(payload) {
  const { data } = await $http.post(`${service}/meta/v2-template-messages`, payload);
  return data;
}

/**
 *
 * @param {object} message
 * @param {string} message.recipientId
 * @param {string} message.origin
 * @param {string} message.channel
 * @param {string} message.provider
 * @param {string} message.providerId
 * @param {string} message.chatRoomId
 * @param {string} message.chatRoomMemberId
 */
async function sendMessage(message) {
  const { data } = await $http.post(`${service}/message`, message);
  return data;
}

async function sendMessageTemplate(message) {
  const { data } = await $http.post(`${service}/message/template`, message);
  return data;
}

/**
 * @description Will retrieve all chat rooms with specific userId as one of its members
 * @param {object} payload
 * @param {string} payload.recipientId
 * @param {string} payload.companyId
 * @returns
 */
async function getAllChats(params) {
  try {
    const { data } = await $http.get(`${service}/room`, { params });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getAllChatsOfMember(params, offset = null, limit = null) {
  const url = new URL(`${service}/member/rooms`);

  if (limit) url.searchParams.append('limit', limit);
  if (offset) url.searchParams.append('offset', offset);

  const { data } = await $http.get(url.toString(), {
    params: {
      ...params,
      ...(params?.useCountAsLimit && { useCountAsLimit: true }),
    },
  });
  return data;
}

export async function getTotalChatsCountOfMember(params) {
  const { data } = await $http.get(`${service}/member/count-rooms`, { params });
  return data;
}

export async function getCompanieRoomsCount(companyId, params) {
  const { data } = await $http.get(`${service}/room/companies/count/${companyId}`, { params });
  return data;
}

/**
 * @description Will update a chat room state
 * @param {object} payload
 * @param {string} payload.id
 * @param {string} payload.open
 * @returns
 */
async function updateChat(payload) {
  try {
    const { data } = await $http.patch(`${service}/room`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @param {object} payload
 * @param {string} payload.companyId
 * @param {string} payload.roomId
 * @returns
 */
async function terminateChat(payload) {
  try {
    const { data } = await $http.patch(`${service}/room/terminate`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function deleteChatById(chatRoomId) {
  const { data } = await $http.delete(`${service}/room/${chatRoomId}`);
  return data;
}

async function markAllAsSeen({ userId }) {
  try {
    const { data } = await $http.put(`${service}/user/${userId}/seen`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 *
 * @param {object} payload
 * @param {string} payload.companyId
 * @param {string} payload.opensUserId
 * @param {number} payload.chatRoomId
 * @param {string} payload.delegatorId
 * @param {'transference'} payload.type
 */
async function createTransferenceInvitation(payload) {
  const { data } = await $http.post(`${service}/invitations`, payload);
  return data;
}

/**
 *
 * @param {object} params
 * @param {string} params.companyId
 * @param {string} params.userId
 */
async function getAllInvitations(params) {
  const { data } = await $http.get(`${service}/invitations`, {
    params,
  });
  return data;
}

/**
 *
 * @param {string} invitationId
 */
async function replyInvitation(invitationId) {
  const { data } = await $http.put(`${service}/invitations/reply`, {
    id: invitationId,
  });
}

/**
 *
 * @param {string} invitationId
 */
async function destroyInvitation(invitationId) {
  const { data } = await $http.delete(`${service}/invitations/${invitationId}`);
}

/**
 *
 * @param {string} chatRoomId
 */
async function destroyAllInvitationsOfChat(chatRoomId, params) {
  const { data } = await $http.delete(`${service}/invitations/chat/${chatRoomId}`, { params });
}

async function findById(id, params) {
  const { data } = await $http.get(`${service}/room/${id}`, { params });
  return data;
}

async function findByProtocol(protocol, params) {
  const { data } = await $http.get(`${service}/room/protocol/${protocol}`, { params });
  return data;
}

async function createRoom(params) {
  const { data } = await $http.post(`${service}/room/`, params);
  return data;
}

async function sendMediaMessage(formData) {
  try {
    const { data } = await $http.post(`${service}/message/media`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      // timeout: 15000, // 15 seconds
    });

    return data;
  } catch (error) {
    throw new Error(`Error sending media message: ${error}`);
  }
}

async function sendAudioMessage(formData) {
  try {
    const { data } = await $http.post(`${service}/message/audio`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      // timeout: 15000, // 15 seconds
    });

    return data;
  } catch (error) {
    throw new Error(`Error sending media message: ${error}`);
  }
}

export async function sendChatToQueue({ queueId, companyId, chatRoomId, recipientId }) {
  const { data } = await $http.post(`${service}/room/send-to-queue`, { queueId, companyId, chatRoomId, recipientId });
  return data;
}

export async function exitChatRoom(payload) {
  const { data } = await $http.post(`${service}/member/exit`, payload);
  return data;
}

export async function updateMemberInChat(memberId, payload) {
  const { data } = await $http.patch(`${service}/member/${memberId}`, payload);
  return data;
}

/**
 *
 * @param {object} payload
 * @param {number} payload.tagId
 * @param {number} payload.chatRoomId
 * @param {string} payload.companyId
 * @param {string} payload.tagName
 */
export async function createChatTag(payload) {
  const { data } = await $http.post(`${service}/chat-tags`, payload);
  return data;
}

/**
 *
 * @param {object} payload
 * @param {number} payload.tagId
 * @param {number} payload.chatRoomId
 * @param {string} payload.companyId
 * @param {string} payload.tagName
 */
export async function deleteChatTag(tagId) {
  const { data } = await $http.delete(`${service}/chat-tags/${tagId}`);
  return data;
}

export async function updateMessageStatus(payload) {
  const { data } = await $http.patch(`${service}/message-status`, payload);
  return data;
}

export async function countUnreadMessagesInRoom({ roomId, userId }) {
  const { data } = await $http.get(`${service}/message-status/unread-by-room/${roomId}/${userId}`);
  return data;
}

export async function countUnreadMessagesInAllUserRoom({ userId }) {
  const { data } = await $http.get(`${service}/message-status/unread-in-rooms/${userId}`);
  return data;
}

export async function markAllMessageAsRead({ userId, roomId }, config = { signal: null }) {
  try {
    const { data } = await $http.patch(`${service}/message-status/mark-all-as-read/${userId}/${roomId}`, null, config);
    return data;
  } catch (error) {}
}

export default {
  connect,
  findById,
  getAllChats,
  markAllAsSeen,
  updateChat,
  sendMessage,
  exitChatRoom,
  updateMemberInChat,
  createChatTag,
  terminateChat,
  deleteChatTag,
  deleteChatById,
  sendChatToQueue,
  createTransferenceInvitation,
  getAllInvitations,
  replyInvitation,
  destroyInvitation,
  destroyAllInvitationsOfChat,
  createRoom,
  sendMetaMessage,
  sendMetaMessageV2,
  getAllChatsOfMember,
  getMessagesByRoom,
  sendMediaMessage,
  sendAudioMessage,
  getLinkPreview,
  updateMessageStatus,
  countUnreadMessagesInRoom,
  countUnreadMessagesInAllUserRoom,
  markAllMessageAsRead,
  sendMessageTemplate,
  findByProtocol,
};
