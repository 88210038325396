<template lang="pug">
.w-full.relative
  .flex.flex-col.mb-2.items-start
    p.font-semibold.text-gray-500.font-inter(v-if="label") {{ label }}
    p.text-xs.text-gray-500(v-if="description") {{ description }}
  v-dropdown.w-full(placement="bottom", autoSize="min")
    .w-full.border.border-gray-200.rounded-md.flex.justify-between.py-3.font-inter.cursor-pointer(
      class="bg-[#F8F9FA]"
    )
      p.ml-5.text-base {{ selectedQueues.length ? `(${selectedQueues.length}) Fila(s) selecionada(s)` : 'Selecione uma ou mais filas' }}
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    template(#popper)
      .p-2
        slot(
          name="search"
          :search="handleSearchQueue"
        )
        .flex.flex-col.max-h-60.overflow-y-scroll.mt-2.items-start.justify-start
          div.w-full(
            v-for="queue in queues.filter((q) => q.toLowerCase().includes(search.toLowerCase()))"
            :key="queue"
            @click="select(queue)"
          )
            slot(
              name="items"
              :queue="queue"
              :selected="selectedQueues.includes(queue)"
            )
          p.font-plus.text-sm.text-center.py-2.text-gray-500(v-if="!queues.length") Nenhuma fila encontrada
        .flex.w-full.justify-center.gap-2.pt-2
          slot(
            name="options"
            :selectAll="selectAll"
            :deselectAll="deselectAll"
          )
</template>

<script>
import { hideAllPoppers, Dropdown } from 'floating-vue';
import rhService from '@/services/rship.js';
import LoadingRing from '../LoadingRing.vue';
import Observer from '../Observer.vue';
import { debounce } from '@/helpers';

export default {
  props: {
    description: { type: String, default: '' },
    label: { type: String, default: '' },
    preSelectedQueues: { type: Array, default: () => [] },
  },
  components: {
    vDropdown: Dropdown,
    LoadingRing,
    Observer,
  },
  data() {
    return {
      search: '',
      lastRequestLength: 0,
      offset: 0,
      loading: false,
      selection: [],
      queues: [],
      selectedQueues: [],
    };
  },
  watch: {
    selectedQueues(val) {
      return this.$emit('selected-queues-list', val);
    },
  },
  methods: {
    select(queue) {
      if (this.selectedQueues.includes(queue)) {
        this.selectedQueues = this.selectedQueues.filter((q) => q !== queue);
      } else {
        this.selectedQueues.push(queue);
      }
    },
    selectAll() {
      this.selectedQueues = this.queues.filter((q) => q.toLowerCase().includes(this.search.toLowerCase()));
    },
    deselectAll() {
      this.selectedQueues = [];
    },
    handleSearchQueue(search) {
      this.search = search;
    },
  },
  async created() {
    this.queues = Object.keys(this.user.queues);
    this.selectedQueues = this.preSelectedQueues;
  },
};
</script>
