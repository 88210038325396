<template lang="pug">
.flex.w-full(
  id="transferBox"
)
  .flex.flex-col.gap-2(v-if="!isCalling")
    .flex.flex-col.items-start
      p.text-white.font-plus Transferir chamada
      p.font-plus.text-gray-300.text-xs Transfira a chamada para um dos seus colegas ou números de telefone
    .flex.flex-col.w-full.gap-2
      icon-input(
        label="Colegas e números",
        v-model="searchInput",
        dark
      )

      dialer-contact-card(
        v-if="isOnlyNumbers(searchInput)",
        name="Ligar Para"
        :endpoint="searchInput"
        @make-call="setTransfering({id: '', number: searchInput})"
      )

      p.font-plus.text-gray-300.text-xs.w-full COLEGAS
      .flex.flex-col.w-full.overflow-y-scroll.justify-start.gap-2(
        :style="{'height': `${availableHeight}px`}"
      )
        dialer-contact-card(
          v-for="mate in mates",
          v-if="mate.id != user.id"
          :key="mate.id",
          :name="mate.username",
          :endpoint="mate.endpoint"
          @make-call="setTransfering({id: mate.id, number: mate.endpoint})"
        )
          template(#avatar)
            avatar(
              :src="`https://${cserviceBaseUrl}/avatar/${mate.id}.png`",
              :avatarId="`${mate.id}`",
            )
          template(#status)
            .absolute.w-3.h-3.rounded-full.bottom-0.left-7.border(
              :class="[translateStatus(mate.status)]"
            )

        p.text-sm.text-gray-600(v-if="!mates.length") Nenhum colega encontrado
        loading-ring(v-if="loadingMates")

  .flex.flex-col.gap-8.items-center.justify-center.w-full(class='h-[25rem]', v-else)
    .font-plus.text-white {{ transferred ? "Em chamada..." : "Transferindo..." }}

    .flex.flex-col.gap-5
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.overflow-hidden
        avatar(
          v-if="!identityContact.unknown"
          :src="identityContact.avatarUrl",
          :avatarId="identityContact.id",
        )
        img.h-full(
          v-else
          src="@/assets/images/default_pfp.png"
        )

      .flex.flex-col.gap-0.items-center
        .text-white.font-semibold.font-plus {{ identityContact?.name }}
        .text-sm.text-slate-500.font-plus {{ transferNumber }}
        .text-sm.text-slate-500.font-plus(v-if="!identityContact?.unknown") {{ identityContact?.organization || "Sem organização" }}

      call-duration(v-if="transferred")

      .flex.gap-2(v-if="loadingHangUp")
        loading-ring

      .flex.gap-2(v-else)

        dialer-option(
          :icon="muted ? 'mic_off' : 'mic'",
          @click="checkMute"
          :selected="muted"
          v-if="isWebRTC"
        )
          template(#tooltip)
            p.font-plus.text-white Ativar/Desativar microfone

        dialer-option(
          red,
          icon="call_end",
          @click="hangUpTransfering"
        )
          template(#tooltip)
            p.font-plus.text-white Desligar chamada
      
</template>

<script>
import DialerContactCard from '@/components/Dialer/dialer-contact-card.vue';
import CallDuration from '@/components/Dialer/dialer-call-duration.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import IconInput from '@/components/Inputs/IconInput.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import contactList from '@/services/contact-list.js';
import SipMixin from '@/mixins/invite-calls.js';
import cservice from '@/services/cservice';
import EventBus from '@/main-event-bus';
import { debounce } from '@/helpers';

export default {
  mixins: [UserOrContactMixin, SipMixin],
  props: ['extra'],
  components: {
    DialerContactCard,
    CallDuration,
    DialerOption,
    LoadingRing,
    IconInput,
    Avatar,
  },
  data() {
    return {
      cserviceBaseUrl: process.env.VUE_APP_CSERVICE,
      transferNumber: '',
      searchInput: '',
      loadingHangUp: false,
      transferred: false,
      isCalling: false,
      callDate: null,
      transferContact: {},
      socket: {},
    };
  },
  watch: {
    extraCall(newValue) {
      this.transferred = true;
      this.callDate = newValue.calldate;
    },
  },
  computed: {
    availableHeight() {
      return this.dialerSize.height - 200;
    },
    dialerSize() {
      return this.$store.getters['sip/size'];
    },
    extraCall() {
      return this.extra;
    },
    loadingMates() {
      return this.$store.getters['sip/loadingMates'];
    },
    mates() {
      return this.$store.getters['sip/matesList']
        .filter((mate) => {
          return mate.username.toLowerCase().includes(this.searchInput.toLowerCase());
        })
        .sort((a, b) => {
          const statusPriority = {
            online: 0,
            oncall: 1,
            pause: 2,
            offline: 3,
          };

          const statusDiff = statusPriority[a.status] - statusPriority[b.status];

          if (statusDiff === 0) {
            return a.username.localeCompare(b.username);
          }

          return statusDiff;
        });
    },
    muted() {
      return this.$store.getters['sip/muted'];
    },
  },
  methods: {
    translateStatus(status) {
      if (status == 'online') return 'bg-green-500';
      if (status == 'oncall') return 'bg-red-500';
      if (status == 'pause') return 'bg-yellow-500';
      if (status == 'offline') return 'bg-gray-500';
      return 'bg-gray-500';
    },
    isOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    async checkMute() {
      if (this.mute == 'off') {
        this.mute = 'on';
        this.$store.commit('sip/setMuted', true);
      } else {
        this.mute = 'off';
        this.$store.commit('sip/setMuted', false);
      }
      try {
        if (this.webphone && this.session) return this.muteSession(this.muted);
        await this.muteFunction(this.mute);
      } catch (error) {
        console.error(error);
        if (this.muted) return this.$toast.error('Não foi possível ativar o microfone');
        this.$toast.error('Não foi possível desativar o microfone');
      }
    },
    async hangUpTransfering() {
      if (this.loadingHangUp) return;
      this.loadingHangUp = true;
      try {
        await this._hangUpCall();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingHangUp = false;
      }
    },
    async setTransfering({ id, number }) {
      try {
        this.attendTransfer(number);
        this.transferNumber = number;
        EventBus.$emit('mixpanel-event', { eventName: 'transfer-call', payload: {} });
        this.isCalling = true;
        this.setContactOrOperator({
          id,
          number: number,
        });
        this.$store.commit('sip/setTransferring', true);
      } catch (e) {
        console.error(e);
        this.$toast.error('Não foi possível transferir esta chamada');
      }
    },
  },
};
</script>
