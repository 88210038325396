<template lang="pug">
overlay(@click="$emit('close-overlay')")
  template(v-slot:header)
    header.w-full.border-b.p-6.flex.gap-4.items-center.justify-between
      h1.text-xl.text-zinc-800.font-plus.font-bold Editar contato
      close-button(@click="$emit('close-overlay')")
  template(#content)
    .flex.flex-col.w-full.h-full.items-start.justify-start.pt-8.pl-8(v-if="loading")
      .flex.gap-2.w-full.h-full.flex-col
        p Carregando informações do usuário...
        .flex 
          loading-ring
    .flex.flex-col.h-full.w-full.font-plus(v-else)
      .flex.w-full.justify-center(v-if="modal === 'InvalidInfo'")
        invalid-info
      .flex.w-full.items-start.gap-12(v-if="contact")
        .flex.flex-col.items-center.justify-center.gap-2
          span.material-icons.notranslate.top-user-icon.select-none.h-48.w-48.items-center.justify-center.flex.bg-brand-200.rounded-md.cursor-pointer.transition-all(
            v-if="imageError && !uploaded"
            @click="showUploadImageModal = true",
            class="active:scale-90 hover:opacity-50"
          ) perm_identity
          img.h-48.w-48.rounded-md.cursor-pointer.transition-all(
            v-else-if="!imageError && !uploaded"
            :src="`${clistUrl}/uploads/${contact.id}.jpg`"
            @error="imageError = true;"
            @click="showUploadImageModal = true",
            class="active:scale-90 hover:opacity-50"
          )
          img.h-48.w-48.rounded-md.cursor-pointer.transition-all(
            v-else-if="uploaded"
            @click="showUploadImageModal = true",
            class="active:scale-90 hover:opacity-50"
            :src="uploadedImage"
          )
          v-button-secondary(
            @click="showUploadImageModal = true"
          ) Adicionar ou alterar foto
          upload-image(
            v-if="showUploadImageModal"
            title="Alterar foto de perfil"
            @close="showUploadImageModal = false"
            @apply-image="handleApplyImage"
          )
        .flex.flex-col.gap-4
          .flex.flex-col.gap-4.w-96.overflow-auto.items-start.justify-start(class="max-h-[80dvh]")
            //- Nome
            simple-input.w-full(ref="contactInput" label="Nome" v-model="contact.name")
            //- Organização
            v-dropdown.w-full(
              placement="bottom-start",
              :triggers="[]",
              :shown="showOrgList",
              :autoHide="false"
              auto-size
            )
              simple-input(
                v-model="selectedOrgName"
                label="Organização"
                @focus="watchOrganization",
                @blur="showOrgList = false"
                :key="contact.organizationId"
                :class="{'!border-red-400': orgError}"
              )
              template(#popper)
                .max-h-72.overflow-auto.divide-y
                  li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
                    v-for="org in organizationList.filter((o) => { return o.name.toLowerCase().startsWith(selectedOrgName.toLowerCase()); })",
                    :key="org.id"
                  )
                    p.w-full.ml-4.text-gray-400.cursor-pointer(
                      @mousedown="selectOrganization(org)"
                    ) {{ org.name }}
                  li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
                    v-if="organizationList.filter((o) => { return o.name.toLowerCase().startsWith(selectedOrgName.toLowerCase()); }).length < 1"
                  )
                    p.w-full.ml-4.text-brand-500.cursor-pointer(
                      @mousedown="addNewOrganization"
                    )
                      span.font-semibold(class="dark:invert") Adicionar nova organização
            //- CPF
            simple-input.w-full(
              label="CPF",
              v-model="contact.cpf",
              type="text",
              :caract="14"
            )
            //- Emails
            .flex.flex-col.gap-2.w-full
              .flex.w-full.gap-2.flex-col.max-h-40.overflow-auto.items-start.justify-start
                simple-input.w-full(
                  v-for="(email, index) in contact.emails"
                  v-model="email.address"
                  label="E-mail"
                  :key="email.id"
                )
              .flex.w-full.justify-end
                a.font-bold.font-inter.text-xs.text-center.text-brand.tracking-widest.w-auto(
                  class="hover:text-opacity-60"
                  role="button",
                  v-on:click="appendEmailInput()",
                ) Adicionar email
            //- Categoria
            v-dropdown.w-full(
              placement="bottom-start",
              :triggers="[]",
              :shown="showTagList",
              :autoHide="false"
              auto-size
            )
              simple-input(
                v-model="selectedCategoryName"
                label="Categoria"
                @focus="showTagList = true"
                @blur="showTagList = false"
                :key="contact.categoryId"
                auto-size
              )
              template(#popper)
                .max-h-72.overflow-auto.divide-y
                  li.flex.justify-start.items-center.h-10.bg-zinc-50.cursor-pointer(
                    v-for="category in computedCategories",
                    :key="category.id"
                  )
                    p.ml-4.text-gray-400.w-full(
                      @mousedown="selectCategory(category)"
                    ) {{ category.name }}

                  li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
                    v-if="!computedCategories.length"
                  )
                    p.ml-4.text-brand-400.cursor-pointer(
                      @mousedown="displayNewCategoryModal=true"
                    )
                      span.font-semibold(class="dark:invert") Adicionar nova categoria
            modal(v-if="displayNewCategoryModal" @click='displayNewCategoryModal=false')
              modal-body
                template(v-slot:header)
                  v-heading Criar categoria

                template(v-slot:body)
                  p.text-sm.text-zinc-400.mb-4 Preencha os campos a baixo para criar uma categoria
                  simple-input.w-96(label='Nova categoria' v-model="newCategoryName")

                template(v-slot:footer)
                  .flex.justify-center.items-center
                    v-button.mr-2(@click='addNewCategory') Criar
                    v-button-secondary(@click='displayNewCategoryModal=false') cancelar
            //- Telefone principal
            #number-div.w-full(v-if="contact.phones")
              label.text-gray-600.font-semibold.p-0 Telefone principal
              .flex.gap-4.w-full
                v-dropdown(placement="bottom")
                  button(class="group flex-1 h-11 hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none")
                    .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") +{{ contact.phones[0].ddi }}
                    span(class="material-icons notranslate group-hover:text-brand text-gray-400") keyboard_arrow_down
                  template(#popper)
                    ul(class="w-full max-h-40 gap-2 flex flex-col p-1 divide-y justify-start")
                      .flex.h-10.w-full.mt-1
                        icon-input(:label="'DDI'" v-model="ddiSearch")
                      dropdown-item(
                        v-for="(ddi, index) in avaliableDdis.filter((ddi) => ddi.number.includes(ddiSearch))",
                        @click="contact.phones[0].ddi = ddi.number", 
                        :label="`${ddi.flag} +${ddi.number}`",
                        :key="index"
                        :hide-icon="true" 
                        :selected="contact.phones[0].ddi === ddi.number"
                      )
                input-phone(
                  label="Número de telefone principal" 
                  v-model="contact.phones[0].number"   
                )
              label.text-gray-600.font-semibold.p-0.mt-2(v-if="contact.phones.length > 1") Outros números
              .flex.flex-col.gap-2.w-full.overflow-auto.max-h-40.items-start.justify-start
                div.w-full(v-for="(number, index) in contact.phones.slice(1, contact.phones.length)" :key="index")
                  .flex.gap-4.w-full
                    v-dropdown(placement="bottom")
                      button(class="group flex-1 h-11 hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none")
                        .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") +{{ number.ddi }}
                        span(class="material-icons notranslate group-hover:text-brand text-gray-400") keyboard_arrow_down
                      template(#popper)
                        ul(class="w-full max-h-40 gap-2 flex flex-col p-1 divide-y justify-start")
                          dropdown-item(
                            v-for="ddi in avaliableDdis",
                            @click="number.ddi = ddi.number", 
                            :label="`${ddi.flag} +${ddi.number}`",
                            :key="index"
                            :hide-icon="true" 
                            :selected="number.ddi === ddi.number"
                          )
                          loading-ring(v-if="loadingCountries")
                    input-phone.flex-1(
                      label="Número de telefone"
                      :index="index + 1", 
                      v-model="number.number"
                    )
                    i.material-icons.notranslate.closeicon-input(v-if="index !== -1" v-on:click="displayDeleteModal=true" style="margin-right: 14px") &#xe5cd
                  modal(v-if="displayDeleteModal" @click='displayDeleteModal=false')
                    modal-body
                      template(v-slot:header)
                        v-heading Confirmação de exclusão
                      template(v-slot:body)
                        p.text-sm.text-zinc-400.mb-4 Deseja excluir este número?
                        p.text-sm.text-zinc-400.mb-4 Essa ação é irreversível. Não será possível recuperar o número excluído.
                      template(v-slot:footer)
                        .flex.justify-center.items-center.gap-4
                          v-button-secondary(@click='displayDeleteModal=false') Cancelar
                          v-button(@click='deletePhone(number, index + 1)') Excluir
            //- Adicionar número
            .flex.w-full.justify-end
              a.font-bold.font-inter.text-xs.text-center.text-brand.tracking-widest.w-auto(
                class="hover:text-opacity-60"
                role="button",
                v-on:click="appendNumberInput()"
              ) Adicionar número
          //- Ações
          .flex.gap-2
            v-button-secondary(@click="$emit('close-overlay')") Cancelar
            v-button(@click="saveChanges") Salvar
</template>

<script>
import { parsePhoneNumber } from '@/utils/google-libphonenumber.util';
import { Dropdown } from 'floating-vue';
import cListService, { getCategoryList } from '@/services/contact-list.js';
import InvalidInfo from '@/components/Modals/InvalidInfo.vue';
import OrganizationInput from '../Contacts/Contact.DropdownInput.vue';
import CategoryInput from '../Contacts/Category.DropdownInput.vue';
import PhoneInput from '../Contacts/Contact.PhoneInput.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import VButton from '@/components/Buttons/v-button.vue';
import SimpleInput from '@/components/Inputs/SimpleInput.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VHeading from '@/components/Text/v-heading.vue';
import overlay from '@/components/Overlay/overlay.vue';
import InputPhone from '@/components/Inputs/InputPhone.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import '../../assets/css/vue-phone-number-input.css';
import UploadImage from '@/components/Modals/UploadImage.vue';
import CloseButton from '@/components/Buttons/v-close-button.vue';

export default {
  title: 'Opens - Editar contato',
  components: {
    overlay,
    CloseButton,
    Modal,
    ModalBody,
    VButton,
    VButtonSecondary,
    OrganizationInput,
    CategoryInput,
    VuePhoneNumberInput,
    UploadImage,
    InvalidInfo,
    PhoneInput,
    VDropdown: Dropdown,
    VHeading,
    SimpleInput,
    InputPhone,
    DropdownItem,
    IconInput,
    LoadingRing,
  },
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showUploadImageModal: false,
      loading: true,
      imageError: false,
      loadingCountries: false,
      selectedCategoryName: '',
      displayNewCategoryModal: false,
      newCategoryName: '',
      showOrgList: false,
      contact: null,
      displayDeleteModal: false,
      uploadedImage: null,
      categories: [],
      updatedPhones: {},
      orgList: [],
      file: null,
      organizationList: [],
      uploaded: false,
      loaded: false,
      index: null,
      payload: {},
      orgError: false,
      inputValue: [],
      selected: false,
      showTagList: false,
      selectedOrgName: '',
      avaliableDdis: [{ number: '55', flag: 'BR' }],
      ddiSearch: '',
      clistUrl: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CLIST}`,
    };
  },
  methods: {
    appendEmailInput() {
      this.contact.emails.push({ address: '' });
    },
    async fetchCountries() {
      this.loadingCountries = true;
      try {
        const res = await this.$http.get('https://restcountries.com/v2/all');

        const avaliableDdis = res.data.map((country) => {
          return {
            number: country.callingCodes[0],
            flag: country.alpha2Code,
          };
        });

        this.avaliableDdis = avaliableDdis;
      } catch (e) {
        this.$toast.error("Erro ao buscar DDI's");
      } finally {
        this.loadingCountries = false;
      }
    },
    formatCpfNumber(value) {
      if (!value) return '';
      let cpf = value?.replace(/\D/g, '')?.slice(0, 11);
      if (!cpf?.length) return '';
      let formattedCPF = '';

      for (let i = 0; i < cpf.length; i++) {
        if (i === 3 || i === 6) {
          formattedCPF += '.';
        } else if (i === 9) {
          formattedCPF += '-';
        }
        formattedCPF += cpf[i];
      }

      return formattedCPF;
    },
    test(number, index) {
      this.$log.info(number.country + ' ' + index);
      return this.contact.phones[index].country;
    },
    loadComponent(ddi, index) {
      setTimeout(() => {
        document.getElementsByClassName('country-selector__label').item(index).innerHTML = '';
        document.getElementsByClassName('input-tel__input').item(index).placeholder = '';
        document.getElementsByClassName('input-tel__label').item(index).style.display = 'none';
      }, 0);
      if (ddi == null) {
        return 'BR';
      }
      return ddi;
    },
    orgExists(org) {
      const orgs = this.organizationList.filter((organization) => org == organization.name);

      return orgs.length > 0;
    },
    selectOrganization(e) {
      this.selected = true;
      this.contact.organizationId = e.id;
      this.selectedOrgName = e.name;
      this.showOrgList = false;
    },
    selectCategory(category) {
      this.selectedCategoryName = category.name;
      this.contact.categoryId = category.id;
      this.showTagList = false;
    },
    resetOrg() {
      this.selected = false;
      this.selectedOrgName = '';
      this.contact.organizationId = null;
    },
    appendNumberInput() {
      this.contact.phones.push({ number: '', ddi: '55', contactId: this.contact.id, companyId: this.user.company_id });
    },
    async saveChanges() {
      if (!this.contact.name) return this.$toast.error('Nome não pode estar vazio');
      try {
        if (this.contact.phones[0].number?.length) {
          for (const phone of this.contact.phones) {
            if (phone.ddi == '55') {
              const { ddiParsed, isValidNumber, phoneNumberParsed } = await parsePhoneNumber(phone.number, phone.ddi);

              if (!isValidNumber) {
                return this.$toast.error('Número de telefone inválido! Verifique também o digito 9');
              }

              phone.number = phoneNumberParsed;
              phone.ddi = ddiParsed;
            } else {
              phone.number = phone.number.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '');
            }
            phone.country = this.avaliableDdis.filter((ddi) => ddi.number == phone.ddi)[0].flag;
            phone.companyId = this.user.company_id;
          }
        } else {
          delete this.contact.phones;
        }

        this.contact.cpf = this.contact?.cpf?.replace(/\D/g, '') || null;
        if (this?.contact?.cpf) {
          if (this.contact?.cpf?.length < 11) {
            return this.$toast.error('CPF inválido');
          }
        }

        await cListService.editContact(this.contact.id, this.contact);
        this.$toast.success('Contato salvo com sucesso!');
        if (this.file) {
          const formData = new FormData();
          formData.append('image', this.file);
          await cListService.uploadContactAvatarWithFormData(this.contact.id, formData);
        }

        this.$emit('contact-edited');
        this.$emit('close-overlay');
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || 'Opa, não conseguimos editar esse contato...', {
          duration: 5000,
        });
      }
    },
    async deletePhone(phone, index) {
      try {
        if (phone.id) await cListService.deletePhoneById(phone.id);
        this.contact.phones.splice(index, 1);
        this.$toast.success('Telefone removido');
        this.displayDeleteModal = false;
      } catch (error) {
        this.$toast.error('Não foi possível remover o telefone...');
      }
    },
    async addNewCategory() {
      try {
        this.selectedCategoryName = '';
        const category = await cListService.createCategory({
          name: this.newCategoryName,
          companyId: this.user.company_id,
        });
        this.categories = await getCategoryList();
        this.$toast.success('Categoria criada com sucesso!');
        this.selectedCategoryName = category.name;
        this.contact.categoryId = category.id;
        this.displayNewCategoryModal = false;
      } catch (error) {
        this.$toast.error('Opa, não conseguimos criar essa categoria...');
      }
    },
    updatePhone(phone, index) {
      this.updatedPhones[index] = {
        id: phone.id,
        number: phone.number,
      };
    },
    async handleApplyImage(data) {
      this.uploaded = true;
      this.imageError = false;
      this.file = data.blobImage;
      this.uploadedImage = URL.createObjectURL(data.blobImage);
      this.showUploadImageModal = false;
    },
    watchOrganization() {
      this.showOrgList = true;
    },
    addNewOrganization() {
      this.selectedOrgName = '';
      this.$store.commit('modal/setActiveModal', 'CreateOrganization');
    },
  },
  computed: {
    modal() {
      return this.$store.getters['modal/activeModal'];
    },
    computedCategories() {
      return this.categories.filter((category) =>
        category.name.toLowerCase().includes(this.selectedCategoryName?.toLowerCase()),
      );
    },
  },
  watch: {
    async modal(value) {
      if (!value) {
        this.organizationList = await cListService.getOrganizationList();
      }
    },
    'contact.cpf'(val) {
      this.$nextTick(() => {
        this.contact.cpf = this.formatCpfNumber(val);
      });
    },
    selectedOrgName(val) {
      if (!val) {
        this.contact.organizationId = null;
      }
    },
    selectedCategoryName(val) {
      if (!val) {
        this.contact.categoryId = null;
      }
    },
  },
  async created() {
    try {
      this.categories = await getCategoryList();
      const contact = await cListService.getContactById(this.contactId);
      this.contact = contact;
      if (this.contact?.category) this.selectedCategoryName = contact.category.name;
      if (this.contact?.organization) this.selectedOrgName = contact.organization.name;
      if (this.contact?.cpf) {
        this.contact.cpf = this.formatCpfNumber(this.contact.cpf);
      }
      if (!this.contact.phones[0]) this.appendNumberInput();
      delete this.contact.organization;
      delete this.contact.category;

      for (let i = 0; i < this.contact.phones.length; i++) {
        this.inputValue[i] = this.contact.phones[i].number;
      }
      this.organizationList = await cListService.getOrganizationList();
      this.loading = false;
    } catch (e) {}
    await this.fetchCountries();
  },
};
</script>
<style scoped>
.remove-contact-modal {
  position: absolute;
  display: none;
  box-shadow: 0px 0px 10000px 10000px rgba(0, 0, 0, 0.8);
  z-index: 9999;
  width: 610px;
  height: 285px;
  background: #ffffff;
  padding: 48px;
}
.delete-button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  color: var(--brand-color) !important;
}
.closeicon-input {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: none;
  padding-top: 0px;
  padding-bottom: 1px;
  padding-right: 0px;
  cursor: pointer;
  color: #fff !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #adb5bd !important;
  transition: 0.2s;
}
.closeicon-input:hover {
  background: #8f9295 !important;
}
</style>
