import { updateCallback, getCallBackById } from '@/services/cblist';
import { updateCallbackReport } from '@/services/call-report';

export default {
  methods: {
    async removeCallBack(callbackId) {
      try {
        await updateCallback({
          callbackId: callbackId,
          payload: {
            status: 303,
          },
        });
      } catch (e) {
        this.$log.info(e);
      }
    },
    async finishCallBack(callbackId) {
      const abandonCallBack = await getCallBackById(callbackId);
      if (abandonCallBack) {
        if (abandonCallBack.linkedid) {
          await updateCallbackReport(abandonCallBack.linkedid);
        }
      }

      const agentId = this.user.id;

      try {
        await updateCallback({
          callbackId: callbackId,
          payload: {
            status: 202,
            completedBy: agentId,
          },
        });
      } catch (e) {
        this.$log.info(e);
      }
    },
  },
};
