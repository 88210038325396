<template lang="pug">
section.w-full.mb-16
  finish-activity-modal(
    @finish-activity="handleFinishCallback"
    @close-modal="isFinishActivityModalOpen = false"
    :activity="activity"
    v-if="isFinishActivityModalOpen"
  )

  cancel-activity-modal(
    @cancel-activity="handleCancelCallback"
    @close-modal="isCancelActivityModalOpen = false"
    :activity="activity"
    v-if="isCancelActivityModalOpen"
  )

  .w-full.mt-8(v-if="loading")
    content-placeholders.w-full.mb-8.ml-4(
      v-for="item in 10"
      :key="item"
      :rounded="true"
    )
      content-placeholders-img.w-full.h-14

  table.leading-normal.text-left.border-collapse.w-full.font-inter.font-normal.text-sm.text-gray-600(v-else)
    thead.w-full.h-14.bg-gray-100.rounded.font-inter.font-semibold.text-sm.leading-5.text-gray-600
      tr
        th.pl-4.h-14(role='columnheader' scope='col') Origem
        th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role="columnheader" scope="col") Ligar para
        th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role="columnheader" scope="col") Observações
        th.mdc-data-table__header-cell(role="columnheader" scope="col") Adicionado
        th.mdc-data-table__header-cell(role="columnheader" scope="col") Ações

    transition-group.mdc-data-table__content(
      tag="tbody",
      name="slide-fade",
      :duration="200",
      mode="out-in"
    )
      assigned-activities(
        v-for="data in callbacks"
        :key="data.id"
        :data="data"
        @openOverlay="openOverlay"
        @calling="$emit('calling', $event)"
        @finish-activity="finishActivity"
        @cancel-activity="cancelActivity"
      )

  observer.py-1(v-if="hasMoreCallbacks" @intersect="fetchMoreCallbacks")
  loading-ring(v-if="loadingMoreCallbacks")
</template>

<script>
import Observer from '@/components/Observer.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import AssignedActivities from './Callbacks.Assigned.vue';
import FinishActivityModal from './Callback.FinishActivityModal';
import CancelActivityModal from './Callback.CancelActivityModal';
import callbackMixin from '@/mixins/callbacks.js';
import { getCallbacks, countCallbacks } from '@/services/cblist';

export default {
  mixins: [callbackMixin],
  components: {
    Observer,
    LoadingRing,
    AssignedActivities,
    FinishActivityModal,
    CancelActivityModal,
  },
  data() {
    return {
      loading: true,
      loadingMoreCallbacks: false,
      hasMoreCallbacks: true,
      isFinishActivityModalOpen: false,
      isCancelActivityModalOpen: false,
      activity: {},

      callbacks: [],
      searchAfter: null,
    };
  },
  methods: {
    async handleFinishCallback(data) {
      await this.finishCallBack(data);
      this.isFinishActivityModalOpen = false;
      await this.fetchCallbacks();
    },
    finishActivity(data) {
      this.activity = data;
      this.isFinishActivityModalOpen = true;
    },
    async handleCancelCallback(data) {
      await this.removeCallBack(data);
      this.isCancelActivityModalOpen = false;
      await this.fetchCallbacks();
    },
    cancelActivity(data) {
      this.activity = data;
      this.isCancelActivityModalOpen = true;
    },
    async fetchCallbacks() {
      this.loading = true;

      try {
        const payload = {
          assignedTo: [this.user.id],
        };

        const response = await getCallbacks(payload);
        const count = await countCallbacks(payload);

        this.hasMoreCallbacks = Boolean(response.nextCursor);
        this.searchAfter = response.nextCursor;
        this.callbacks = response.data ?? [];

        this.$emit('update-count', count);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreCallbacks() {
      if (!this.hasMoreCallbacks || this.loadingMoreCallbacks) return;

      this.loadingMoreCallbacks = true;

      try {
        const payload = {
          searchAfter: this.searchAfter,
          assignedTo: [this.user.id],
        };

        const response = await getCallbacks(payload);

        this.hasMoreCallbacks = Boolean(response.nextCursor);
        this.searchAfter = response.nextCursor;
        this.callbacks = [...this.callbacks, ...response.data];
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingMoreCallbacks = false;
      }
    },
    openOverlay(data) {
      this.$emit('open-overlay', data);
    },
  },
  async created() {
    await this.fetchCallbacks();
  },
};
</script>
