<template lang="pug">
v-dropdown
  .dropdown-box
    span.dropdown-text {{ placeholder }}
    span.material-icons.notranslate.dropdown-icon(v-if="placeholder === 'Período'")
      | keyboard_arrow_down
    span.material-icons.notranslate.dropdown-icon(v-else='' @click.stop='resetFilter')
      | cancel
  template(#popper)
    span.flex.flex-col
      v-date-picker.border-none(
        color='orange'
        :columns='$screens({ lg: 2 }, 1)'
        v-model='range'
        :mode="mode"
        :max-date="new Date()"
        is-range
        is24hr
      )

      span.py-2.border-t.border-gray-200.w-full.flex.items-center.justify-center
        v-button(
          @click='applyFilter'
        ) Aplicar
</template>

<script>
import { Dropdown, hideAllPoppers } from 'floating-vue';
import VButton from '@/components/Buttons/v-button.vue';

export default {
  props: {
    mode: {
      type: String,
      default: 'date',
    },
    start: {
      type: [String, Number],
      default: new Date().setHours(0, 0, 0, 0),
    },
    end: {
      type: [String, Number],
      default: new Date().setHours(23, 59, 59, 0),
    },
  },
  components: {
    VDropdown: Dropdown,
    VButton,
  },
  data() {
    return {
      range: {
        start: '',
        end: '',
      },
      placeholder: 'Período',
    };
  },
  methods: {
    resetFilter() {
      this.placeholder = 'Período';
      this.range = {
        start: new Date().setHours(0, 0, 0, 0),
        end: new Date().setHours(23, 59, 59, 0),
      };
      this.$emit('reset-filter');
    },
    activityDateFormat(item) {
      let localDate = this.$moment.tz(item, this.user?.timezone || 'America/Sao_Paulo');
      return localDate.format('DD/MM/YYYY');
    },
    applyFilter() {
      this.placeholder = this.activityDateFormat(this.range.start) + ' - ' + this.activityDateFormat(this.range.end);

      this.$emit('date-pick', {
        start: this.$moment(this.range.start).toISOString(),
        end: this.$moment(this.range.end).toISOString(),
      });

      hideAllPoppers();
    },
  },
  watch: {
    range: function (range) {
      this.searching = false;

      const { start, end } = range;

      let payload;

      if (this.mode === 'date') {
        payload = {
          start: this.$moment(start).startOf('day').toISOString(),
          end: this.$moment(end).endOf('day').toISOString(),
        };
      } else {
        payload = {
          start: this.$moment(start).toISOString(),
          end: this.$moment(end).toISOString(),
        };
      }
    },
  },
  created() {
    if (!this.start && !this.end) return;
    this.range = {
      start: this.start,
      end: this.end,
    };
  },
};
</script>

<style lang="scss">
.history-dropdown-content {
  width: 22%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.dropdown-icon {
  font-size: 23px !important;
  color: #adb5bd;
  cursor: pointer;
  transition: 0.1s;
}
.dropdown-box {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: space-between;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
.dropdown-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  width: 80%;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  color: #868e96;
}
.vc-pane-container div:not([class]) {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.vc-date {
  padding-left: 0px !important;
}
.vc-time-picker {
  position: relative;
  padding-top: 40px !important;
  margin-top: 32px;
  border: none !important;
  &:first-child::before {
    content: 'De:';
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
    position: absolute;
    top: 0px;
    left: 16px;
  }
  &:last-child::before {
    content: 'Até:';
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
    position: absolute;
    top: 0px;
    left: 16px;
  }
  div:not([class]) {
    display: none;
  }
}
.vc-select-arrow {
  padding-right: 16px !important;
  svg {
    width: 22px !important;
    height: 22px !important;
  }
}
.vc-select select {
  padding: 14px 16px !important;
  font-family: Inter !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;
  color: #868e96 !important;
  width: 80px !important;
  height: 48px !important;
  background: #f8f9fa !important;
  border: 1px solid #e9ecef !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.inner-focus-input:focus-within {
  transition: 0.2s;
  background-color: #fff !important;
  border: 1px solid #495057;
  input {
    transition: 0.2s;
    background-color: #fff !important;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
