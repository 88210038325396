<template lang="pug">
overlay
  template(v-slot:header)
    header.w-full.border-b.p-6.flex.gap-4.items-center.justify-between
      h1.text-xl.text-zinc-800.font-plus.font-bold Adicionar contato
      close-button(@click="$emit('close-overlay')")
  template(#content)
    div.h-full.w-full(ref="overlay-content")
      add-contact-content.pr-12(
      :is-overlay="true"
      :overlay-contact="overlayContact"
      @close-overlay="$emit('close-overlay')"
    )
</template>

<script>
import AddContactContent from '@/pages/Contacts/AddContactContent.vue';
import overlay from './overlay.vue';
import CloseButton from '@/components/Buttons/v-close-button.vue';

export default {
  components: {
    AddContactContent,
    overlay,
    CloseButton,
  },

  props: {
    overlayContact: {
      type: Object,
      required: true,
    },
  },
};
</script>
