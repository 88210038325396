import { getContactById, getContactByPhoneNumber } from '../services/contact-list';
export default {
  namespaced: true,
  state: {
    contactsById: {},
    contactsByPhoneNumber: {},
  },
  mutations: {
    setContactById(state, payload) {
      const contactObj = { [payload.id]: payload, ...state.contactsById };
      state.contactsById = Object.assign({}, contactObj);
    },
    setContactByPhoneNumber(state, payload) {
      const contactObj = { [payload.id]: payload, ...state.contactsByPhoneNumber };
      state.contactsByPhoneNumber = Object.assign({}, contactObj);
    },
  },
  getters: {
    contactById(state) {
      return (id) => state.contactsById[id];
    },
    getContactByPhoneNumber(state) {
      return (number) => state.contactsByPhoneNumber[number];
    },
  },
  actions: {
    async fetchContactById(context, contactId) {
      try {
        const contact = await getContactById(contactId);
        context.commit('setContactById', contact);
        return contact;
      } catch (error) {}
    },
    async fetchContactByPhoneNumber(context, contactPhoneNumber) {
      try {
        const contact = await getContactByPhoneNumber(contactPhoneNumber);
        context.commit('setContactByPhoneNumber', contact);
        return contact;
      } catch (error) {}
    },
  },
};
