<template lang="pug">
.pr-8.pl-8.w-full.flex.flex-col.items-start
  cancel-activity-modal(
    @cancel-activity="handleCancelActivity",
    @close-modal="closeModal",
    :activity="actualActivity",
    v-if="actualModal === 'CancelActivity'"
  )
  finish-activity-modal(
    @finish-activity="handleFinishActivity",
    @close-modal="closeModal",
    :activity="actualActivity",
    v-if="actualModal === 'FinishActivity'"
  )
  assigned-activity-modal(v-if="actualModal === 'AssignedActivity'" @close-modal="actualModal = ''")
  overlay-activity-detail(@close-overlay="overlay = ''" :activityData="overlayActivity" v-if="overlay === 'ActivityDetail'")
  h1.text-gray-700.font-plus.mt-8.font-semibold.text-2xl Próximas atividades
  h2.font-plus.text-base.mt-2.text-gray-600 Lista de próximas atividades de retorno
  .flex.w-full.mt-7.mb-8
    p(
      class="cursor-pointer min-w-max p-2 border-b-2 transition duration-200"
      :class="selectedTab === tabsEnum.myCallbacks ? 'font-bold border-brand' : 'border-gray-200 hover:border-brand-100'"
      @click="selectTab(tabsEnum.myCallbacks)"
    ) Minhas Atividades ({{ myCallbacksTotal }})

    p(
      class="cursor-pointer min-w-max p-2 border-b-2 transition duration-200"
      :class="selectedTab === tabsEnum.notAssigned ? 'font-bold border-brand' : 'border-gray-200  hover:border-brand-100'"
      @click="selectTab(tabsEnum.notAssigned)"
    ) Não Atribuídas ({{ abandonCallbacksPaginationTotal }})

    p(
      class="cursor-pointer min-w-max p-2 border-b-2 transition duration-200",
      :class="selectedTab === tabsEnum.calls ? 'font-bold border-brand' : 'border-gray-200 hover:border-brand-100'",
      @click="selectTab(tabsEnum.calls)"
    ) Chamadas

    p(
      class="cursor-pointer min-w-max p-2 border-b-2 transition duration-200",
      :class="selectedTab === tabsEnum.chats ? 'font-bold border-brand' : 'border-gray-200 hover:border-brand-100'",
      @click="selectTab(tabsEnum.chats)"
    ) Chat

    p(
      class="cursor-pointer min-w-max p-2 border-b-2 transition duration-200",
      :class="selectedTab === tabsEnum.emails ? 'font-bold border-brand' : 'border-gray-200 hover:border-brand-100'",
      @click="selectTab(tabsEnum.emails)"
      v-if="isAllowedToUseEmail"
    ) Email

    .pl-2.w-full.border-b-2.border-gray-200.mt-auto

  assigned-callbacks-section(
    v-if="selectedTab === tabsEnum.myCallbacks"
    @update-count="myCallbacksTotal = $event"
    @open-overlay="openOverlay"
    @calling="call"
    :key="activitiesKey"
  )

  not-assigned-callbacks-section(
    v-else-if="selectedTab === tabsEnum.notAssigned"
    @update-count="abandonCallbacksPaginationTotal = $event"
    @open-overlay="openOverlay"
    @calling="call"
    :key="activitiesKey"
  )

  .container-fluid(v-else-if="selectedTab === tabsEnum.calls || selectedTab === tabsEnum.emails")
    .header.flex.gap-4(style="margin-top: 24px" v-if="selectedTab === tabsEnum.calls")
      date-picker(
        class="w-1/4"
        @date-pick="filterPeriod"
        @reset-filter="resetPeriodFilter"
        v-if="selectedTab === tabsEnum.calls"
        mode="dateTime"
      )

      input(
        type="text"
        class="w-1/4 bg-[#f8f9fa] border border-solid border-[#e9ecef] rounded h-12 indent-3 text-base font-inter placeholder:text-gray-300 py-2 px-1 focus:bg-white focus:border-[#aaa] transition-all duration-300 ease-linear"
        v-model="callFromNumberOrToNumberFilterValue"
        @input="filterCallByNumber"
        placeholder="Filtrar por número"
      )

      queue-filter(class="w-1/4" @filter="filterQueue" :options="queueOptions" v-if="selectedTab === tabsEnum.calls")

      v-button-secondary(
        class="font-inter flex ml-auto"
        v-if="selectedTab === tabsEnum.calls"
        @click="advancedFiltersModalOpen = true"
      )
        span.text-brand.material-icons.mr-2(class="dark:invert-0") filter_list
        span.text-base Mais filtros

      modal(@click="advancedFiltersModalOpen = false" v-if="advancedFiltersModalOpen && selectedTab === tabsEnum.calls")
        modal-body(class="min-w-[50%]")
          template(v-slot:header)
            .flex.justify-between
              h4.text-2xl.text-gray-500.font-semibold.font-inter Filtros avançados
              v-button-secondary(@click="resetAdvancedFilters")
                span.text-base Limpar filtros avançados
          template(v-slot:body)
            section
              label.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Tipo de atividade
              dropdown-filter(
                class="w-full z-50"
                @filter="setActivityFilterValues"
                :activity-selected="activitySelected"
                :options="activityTypes"
              )

            section
              label.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Tag
              tag-filter.w-full(
                @filter="filterTag"
                :tag-selected="tagSelected"
                :options="tagOptions"
              )

            section
              label.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Protocolo
              input(
                type="text"
                class="w-full bg-[#f8f9fa] border border-solid border-[#e9ecef] rounded h-12 indent-3 text-base font-inter placeholder:text-gray-300 py-2 px-1 focus:bg-white focus:border-[#aaa] transition-all duration-300 ease-linear"
                v-model="callProtocolFilterValue",
                placeholder="Protocolo",
              )

          template(v-slot:footer)
            .flex.gap-4
              v-button-secondary(type="button" @click="advancedFiltersModalOpen = false") Fechar
              v-button-primary(type="button" @click="applyAdvancedFilters") Aplicar

    .history-table-content(:class="{'pt-6' : selectedTab === tabsEnum.calls}" v-if="!loading")
      historic-table(v-if="selectedTab === tabsEnum.calls" :calls="calls")
      historic-table(v-if="selectedTab === tabsEnum.emails" :emails="emails")

    .flex.w-full.justify-center.items-center.mb-4(v-if="selectedTab === tabsEnum.calls")
      observer.py-1(
        v-if="hasMoreCalls",
        @intersect="getMoreCalls"
      )
      loading-ring(v-if="loadingMoreCalls")

    .flex.w-full.justify-center.items-center.mb-4(v-if="selectedTab === tabsEnum.emails")
      observer(
        v-if="hasMoreEmails",
        @intersect="getMoreEmails"
      )
      loading-ring(v-if="loadingMoreEmails")

    .w-full.mt-8(v-if="loading")
      content-placeholders.w-full.mb-8.ml-4(v-for="item in 10" :key="item" :rounded="true")
        content-placeholders-img.w-full.h-14

  chat-section(v-else-if="selectedTab === tabsEnum.chats")

</template>

<script>
import OverlayActivityDetail from './Callback.OverlayActivityDetail';
import CancelActivityModal from './Callback.CancelActivityModal';
import FinishActivityModal from './Callback.FinishActivityModal';
import AssignedActivityModal from './Callback.AssignedActivityModal';
import AssignedActivities from './Callbacks.Assigned.vue';
import HistoricTable from './Callbacks.HistoricTable.vue';
import DropdownFilter from './Callbacks.History.DropdownFilter.vue';
import QueueFilter from './Callbacks.History.QueueFilter.vue';
import TagFilter from './Callbacks.History.TagFilter.vue';
import csService from '@/services/cservice.js';
import rhService from '@/services/rship';
import callReportService from '@/services/call-report';
import { getAllChatsOfMember, getTotalChatsCountOfMember, getChatsAnalytical } from '@/services/chat';
import callbackMixin from '@/mixins/callbacks.js';
import { debounce } from '@/helpers';
import contactList from '@/services/contact-list.js';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import DatePicker from '@/components/DatePicker.vue';
import EventBus from '@/main-event-bus';
import VButtonPrimary from '@/components/Buttons/v-button.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import Observer from '@/components/Observer.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import ChatSection from './Callbacks.ChatSection.vue';
import NotAssignedCallbacksSection from './Callbacks.NotAssignedSection.vue';
import AssignedCallbacksSection from './Callbacks.AssignedSection.vue';
import { getCallbacks, countCallbacks, asignCallBack } from '@/services/cblist';

export default {
  title: 'Opens - Atividades',
  mixins: [callbackMixin],
  components: {
    OverlayActivityDetail,
    CancelActivityModal,
    FinishActivityModal,
    AssignedActivityModal,
    AssignedActivities,
    HistoricTable,
    DropdownFilter,
    QueueFilter,
    TagFilter,
    Modal,
    ModalBody,
    VButtonPrimary,
    VButtonSecondary,
    Observer,
    LoadingRing,
    ChatSection,
    NotAssignedCallbacksSection,
    AssignedCallbacksSection,
    DatePicker,
  },
  data() {
    return {
      activitiesKey: 0,
      emailWhiteListedCompanies: process.env.VUE_APP_EMAIL_WHITE_LIST,
      selectedTab: 2,
      tabsEnum: {
        myCallbacks: 0,
        notAssigned: 1,
        calls: 2,
        chats: 3,
        emails: 4,
      },

      overlay: '',
      overlayActivity: {},
      actualActivity: {},
      actualModal: '',
      loaded: false,

      loading: true,
      periodFilterValue: {
        start: new Date('2000-05-06T17:05:47.796Z'),
        end: new Date('3000-05-06T17:05:47.796Z'),
      },
      callTagFilterValue: null,
      callToIdFilterValue: null,
      callFromIdFilterValue: null,
      callStatusFilterValue: '',
      callProtocolFilterValue: '',
      callQueueFilterValue: '',
      callFromNumberOrToNumberFilterValue: '',

      activitySelected: 'Tipo de atividade',
      tagSelected: 'Tag',

      activityTypes: [
        {
          name: 'Answered Outgoing',
          id: 1,
          value: 'Chamada efetuada atendida',
        },
        {
          name: 'Answered Incoming',
          id: 2,
          value: 'Chamada recebida atendida',
        },
        {
          name: 'Unanswered Incoming',
          id: 3,
          value: 'Chamada recebida não atendida',
        },
        {
          name: 'Unanswered Outgoing',
          id: 4,
          value: 'Chamada efetuada não atendida',
        },
      ],
      textForCallType: {
        made: 'Chamada efetuada atendida',
        received: 'Chamada recebida atendida',
        missed: 'Chamada recebida não atendida',
        missed_outgoing: 'Chamada efetuada não atendida',
      },

      emails: [],
      emailsCurrentScore: null,
      hasMoreEmails: false,
      loadingMoreEmails: false,
      emailsTotal: 0,

      calls: [],
      callsSort: null,
      hasMoreCalls: false,
      loadingMoreCalls: false,
      queueOptions: [],
      tagOptions: [],
      advancedFiltersModalOpen: false,

      myCallbacks: [],
      myCallbacksTotal: 0,
      myCallbacksSearchAfter: null,
      myCallbacksSearchBefore: null,
      myCallbacksPage: 1,
      myCallbacksNextScore: null,
      myCallbacksPreviousScore: null,

      abandonCallbacksPaginationTotal: 0,
    };
  },
  async created() {
    this.identifyTabByUrl();

    try {
      await this.countCallbacks();
      this.loaded = true;

      this.callFromIdFilterValue = this.user.id;
      this.callToIdFilterValue = this.user.id;

      if (this.tabsEnum.calls === this.selectedTab) {
        const [calls, queues, tags] = await Promise.all([
          this.fetchCalls(),
          csService.getCompanyQueues(),
          rhService.getTags(this.user.company_id),
        ]);
        this.queueOptions = queues;

        this.tagOptions = [{ id: 0, text: 'Sem tags' }, ...tags];

        this.calls = this.calls.concat(calls?.data || []);
        this.callsSort = calls?.sort || null;
        this.hasMoreCalls = Boolean(calls?.sort);
      }
    } catch (error) {
      this.$toast.open({
        type: 'error',
        message: 'Falha ao buscar atividades',
      });
      this.$toast.open({
        type: 'error',
        message: 'Falha ao buscar histórico de chamadas',
      });
    } finally {
      this.loading = false;
    }
  },
  watch: {
    async selectedTab(val) {
      if (val === this.tabsEnum.calls) {
        this.resetCallsPagination();
        await this.getCalls();
      } else if (val === this.tabsEnum.emails) {
        this.resetEmailsPagination();
        await this.getEmails();
      }
    },
  },
  methods: {
    async handleCancelActivity(data) {
      await this.removeCallBack(data);
      this.actualModal = '';
      this.activitiesKey += 1;
    },
    async handleFinishActivity(data) {
      await this.finishCallBack(data);
      this.actualModal = '';
      this.activitiesKey += 1;
    },
    async selectTab(tab) {
      this.selectedTab = tab;

      const query = {
        tab: Object.keys(this.tabsEnum).find((key) => this.tabsEnum[key] === tab),
      };

      this.$router.push({
        query,
      });
    },
    async identifyTabByUrl() {
      switch (this.$route?.query?.tab) {
        case 'myCallbacks':
        case 'activity':
          this.$router.push({
            query: {
              tab: 'myCallbacks',
            },
          });
          this.selectedTab = this.tabsEnum.myCallbacks;
          break;
        case 'calls':
          this.selectedTab = this.tabsEnum.calls;
          break;
        case 'chats':
          this.selectedTab = this.tabsEnum.chats;
          break;
        case 'emails':
          this.selectedTab = this.tabsEnum.emails;
          break;
        case 'notAssigned':
          this.selectedTab = this.tabsEnum.notAssigned;
          break;
        default:
          this.$router.push({
            query: {
              tab: 'calls',
            },
          });
          this.selectedTab = this.tabsEnum.calls;
          break;
      }
    },
    async getEmails() {
      this.loading = true;

      try {
        this.emailsCurrentScore = null;

        const { data, currentScore, total } = await getChatsAnalytical({
          operatorsIds: [this.user.id],
          companyId: this.user.company_id,
          provider: this.emailProviders,
          createdAtGte: new Date('2000-01-01'),
          createdAtLte: new Date('3000-01-01'),
        });

        this.emailsCurrentScore = currentScore;
        this.emails = data;
        this.hasMoreEmails = this.emails.length < total;
      } catch (error) {
        this.$toast.error('Erro ao carregar chats');
      } finally {
        this.loading = false;
      }
    },
    async getMoreEmails() {
      this.loadingMoreEmails = true;

      try {
        const { data, currentScore, total } = await getChatsAnalytical({
          operatorsIds: [this.user.id],
          companyId: this.user.company_id,
          provider: this.emailProviders,
          createdAtGte: new Date('2000-01-01'),
          createdAtLte: new Date('3000-01-01'),
          searchAfter: [this.emailsCurrentScore],
        });

        this.emailsCurrentScore = currentScore;
        this.emails = [...this.emails, ...data];
        this.hasMoreEmails = this.emails.length < total;
      } catch (error) {
        this.$toast.error('Erro ao carregar mais chats');
      } finally {
        this.loadingMoreEmails = false;
      }
    },
    async fetchCalls() {
      const payload = {
        limit: 25,
        createdAtLt: new Date(this.periodFilterValue.end).toISOString(),
        createdAtGt: new Date(this.periodFilterValue.start).toISOString(),
        companyId: this.user.company_id,
      };

      if (this.callFromIdFilterValue) payload.fromId = this.callFromIdFilterValue;
      if (this.callToIdFilterValue) payload.toId = this.callToIdFilterValue;
      if (this.callQueueFilterValue) payload.queues = this.callQueueFilterValue;
      if (this.callStatusFilterValue) payload.status = this.callStatusFilterValue;
      if (this.callProtocolFilterValue) payload.protocol = this.callProtocolFilterValue;
      if (this.callFromNumberOrToNumberFilterValue) {
        payload.fromNumber = this.callFromNumberOrToNumberFilterValue;
        payload.toNumber = this.callFromNumberOrToNumberFilterValue;
      }
      if (this.callsSort) payload.searchAfter = this.callsSort;

      if (this.callTagFilterValue?.id === 0) {
        payload.noTag = true;
      } else {
        payload.tagIds = this.callTagFilterValue?.id;
      }

      EventBus.$emit('mixpanel-event', {
        eventName: 'filter-calls-callback',
        payload: {
          createdAtLt: payload.createdAtLt,
          createdAtGt: payload.createdAtGt,
        },
      });

      return await callReportService.getCallsByScroll(payload);
    },
    async getCalls() {
      const calls = await this.fetchCalls();
      this.calls = calls?.data || [];
      this.callsSort = calls?.sort || null;
      this.hasMoreCalls = Boolean(calls?.sort);
    },
    async getMoreCalls() {
      this.loadingMoreCalls = true;
      try {
        const calls = await this.fetchCalls();
        this.calls = this.calls.concat(calls?.data || []);
        this.callsSort = calls?.sort || null;
        this.hasMoreCalls = Boolean(calls?.sort);
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de chamadas',
        });
      } finally {
        this.loadingMoreCalls = false;
      }
    },
    resetCallsPagination() {
      this.callsSort = null;
    },
    resetEmailsPagination() {
      this.callsSort = null;
    },
    setActivityFilterValues(data) {
      if (data === 'reset') {
        this.activitySelected = 'Tipo de atividade';
        this.callStatusFilterValue = '';
        this.callFromIdFilterValue = this.user.id;
        this.callToIdFilterValue = this.user.id;
        return;
      }

      this.activitySelected = data.value;

      this.callStatusFilterValue = data.name.toLowerCase().includes('unanswered') ? 'noanswer' : 'answered';
      this.callFromIdFilterValue = data.name.toLowerCase().includes('outgoing') ? this.user.id : null;
      this.callToIdFilterValue = data.name.toLowerCase().includes('incoming') ? this.user.id : null;
    },
    async filterQueue(data) {
      this.loading = true;
      this.resetCallsPagination();
      this.callQueueFilterValue = data;
      await this.getCalls();
      this.loading = false;
    },
    async filterPeriod(data) {
      this.loading = true;
      this.resetCallsPagination();
      this.periodFilterValue = data;
      await this.getCalls();
      this.loading = false;
    },
    async resetPeriodFilter() {
      this.loading = true;
      this.resetCallsPagination();
      this.periodFilterValue = {
        start: new Date('2000-05-06T17:05:47.796Z'),
        end: new Date('3000-05-06T17:05:47.796Z'),
      };
      await this.getCalls();
      this.loading = false;
    },
    filterTag(data) {
      if (data === 'reset') {
        this.callTagFilterValue = null;
        this.tagSelected = 'Tag';
        return;
      }

      this.callTagFilterValue = data;
      this.tagSelected = data.text;
    },
    async resetAdvancedFilters() {
      this.loading = true;

      this.resetCallsPagination();

      // resets
      // protocol
      this.callProtocolFilterValue = '';

      // activity type
      this.setActivityFilterValues('reset');

      // tag
      this.filterTag('reset');

      this.advancedFiltersModalOpen = false;

      await this.getCalls();

      this.loading = false;
    },
    async applyAdvancedFilters() {
      this.loading = true;
      this.resetCallsPagination();
      this.advancedFiltersModalOpen = false;
      await this.getCalls();
      this.loading = false;
    },
    filterCallByNumber: debounce(async function () {
      this.loading = true;
      this.resetCallsPagination();
      await this.getCalls();
      this.loading = false;
    }, 500),

    translateCallType(item) {
      const numberToSearch = this.user.endpoint;

      const returnIcon = {
        ANSWERED: (call) => {
          if (call.from === numberToSearch) {
            return 'made';
          }
          return 'received';
        },
        NOANSWER: (call) => {
          if (call.from === numberToSearch) {
            return 'missed_outgoing';
          }
          return 'missed';
        },
      };
      const type = returnIcon[item.status];
      return type(item);
    },
    async call({ payload, index }) {
      const num = payload.contactNumber;
      try {
        if (this.$store.getters['sip/agent']) {
          this.$store.dispatch('sip/makeCall', { callNum: num });
        } else this.callNumber(num);
        await this.setResponsible(payload.id);
      } catch (e) {
        this.$toast.error('Falha ao completar a ligação, tente novamente!');
      } finally {
        await this.countCallbacks();
        await this.fetchMyCallbacks();
      }
    },
    async setResponsible(id) {
      try {
        const res = await asignCallBack(this.user.id, id);
        this.$log.info(res);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async countCallbacks() {
      try {
        const totalNotAssignedCallbacks = await countCallbacks({
          queues: Object.keys(this.user.queues),
          categories: ['abandon'],
          notAssigned: true,
        });
        const totalMyCallbacks = await countCallbacks({
          assignedTo: [this.user.id],
        });

        this.abandonCallbacksPaginationTotal = totalNotAssignedCallbacks;
        this.myCallbacksTotal = totalMyCallbacks;
      } catch (e) {
        this.$log.info(e);
      }
    },
    action({ actualModal, actualActivity }) {
      this.actualModal = actualModal;
      this.actualActivity = actualActivity;
      this.overlay = '';
    },
    openOverlay(activity) {
      this.overlayActivity = activity;
      this.overlay = 'ActivityDetail';
    },
    closeModal() {
      this.actualModal = '';
      this.overlay = '';
    },
  },
  computed: {
    emailProviders() {
      return process.env.VUE_APP_EMAIL_PROVIDERS.split(',');
    },
    isAllowedToUseEmail() {
      if (!this.emailWhiteListedCompanies) return true;
      return this.emailWhiteListedCompanies?.split(',').includes(this.user.company_id);
    },
  },
  mounted() {
    this.$root.$on('action', this.action);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.no-tag-margin {
  font-size: 12px !important;
  margin-left: 10px;
}

.callback-table {
  line-height: normal;
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}

.callback-origin-create-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 22px;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
  margin-left: 2.5px;
}

.callback-call-icon {
  display: flex !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: var(--green-default);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 22px !important;
  display: flex;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: 0.2s;
}

.callback-call-icon:hover {
  box-shadow: 0px 7px 16px -2px rgba(0, 0, 0, 0.2) !important;
}

.callback-add-content {
  padding-right: 10px;
}

.callback-action-icon {
  display: flex !important;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  background: #adb5bd;
  cursor: pointer;
  width: 24px;
  color: #fff;
  font-size: 18px !important;
  margin-right: 10px;
  height: 24px;
  transition: 0.1s;
  border-radius: 50%;
}

.callback-action-icon:hover {
  background: #8e949b;
}

.callback-actions-icon-aligner {
  display: flex;
  align-items: center;
  flex-direction: row;
  pointer-events: none;
}

.callback-actions-content {
  pointer-events: none;
}

.callback-obs-content {
  max-width: 222px;
  max-height: 39px;
  padding-right: 15px;
  word-wrap: anywhere;
}

.callback-origin-content {
  padding-left: 16px;
  padding-right: 5px;
  width: fit-content;
}

.callback-origin-tag {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background: rgba(74, 142, 244, 0.1);
  border-radius: 4px;
  width: max-content;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4a8ef4;
}

.callback-callto-content {
  padding-right: 5px;
}

.callback-callto-content-aligner {
  display: flex;
  flex-direction: row;
}

.callback-callto-info-aligner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.callback-callto-number-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 15px;
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}

.callback-callto-tag-margin {
  margin-top: 4px;
  margin-left: 10px;
}

.user-tag {
  padding: 2px 6px;
  height: max-content;
  line-height: normal;
  width: fit-content;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.1);
  border-radius: 25px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #495057;
}

.callback-callto-name-text {
  margin-bottom: 0px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 22px;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}

.callback-obs-content {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}

.callback-callto-user-img {
  background: var(--brand-color-op-1);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--brand-color);
  height: 40px !important;
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.callback-origin-text {
  margin-left: 2px;
  margin-bottom: 2.5px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}

.callback-table-starter {
  padding-left: 16px;
  height: 56px;
}

.callback-table-row {
  padding-left: 12.5px;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
  height: 89px;
}

.callback-table-row:hover {
  background-color: #f8f8f8;
}

.callback-thead {
  width: 100%;
  height: 56px;
  background: #f5f5f5;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}

.callback-activity-content {
  width: 100%;
  padding-left: 12.5px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  height: 89px;
}

.pagination-icon {
  color: #868e96;
  margin-left: 5px;
  cursor: pointer;
  font-size: 18px !important;
}

.pagination-forward-animation {
  animation: forwardAnimation 0.5s linear;
}

.pagination-back-animation {
  animation: backAnimation 0.5s linear;
}

@keyframes backAnimation {
  20% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(10px);
    opacity: 0;
  }

  80% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes forwardAnimation {
  20% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-10px);
    opacity: 0;
  }

  80% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.pagination-icon:hover {
  color: var(--brand-color);
}

.pagination-select {
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
  outline: none;
}

.pagination-aligner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  justify-content: flex-end;
}

.pagination-size-text {
  margin-left: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
}

.pagination-info {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
}

.history-table-content {
  width: 100%;
  padding-bottom: 32px;
}

.tag {
  min-width: max-content !important;
}

.received {
  background: var(--green-default);
}

.missed {
  background: var(--red-default);
}

.history-search-input {
  height: 48px;
  width: 15%;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
  text-indent: 3px;
}
</style>
