<template lang="pug">
modal(@click="$emit('close-modal')")
  modal-body(class="max-w-[27rem]")
    template(v-slot:header)
      h4.text-2xl.text-gray-600.font-semibold.font-plus Cancelar atividade

    template(v-slot:body)
      p.text-gray-500.font-plus.text-base Deseja cancelar a atividade de retorno para
        a.text-brand.font-semibold.font-plus {{ ` ${activity.contact.name} ` }}
        a.font-plus {{ 'criada por ' }}
        a.text-brand.font-semibold.font-plus(v-if="activity.assignedBy.username") {{ activity.assignedBy.username }}
        a.text-brand.font-semibold.font-plus(v-else) abandono na fila {{ activity.payload.description }}
        a.font-plus ?

    template(v-slot:footer)
      .flex.gap-4
        v-button-secondary(@click="$emit('close-modal')" :disabled="loading") Cancelar
        v-button-primary(@click="handleCancelActivity" :disabled="loading") Confirmar
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButton from '@/components/Buttons/v-button.vue';

export default {
  components: {
    Modal,
    ModalBody,
    VButtonSecondary,
    VButtonPrimary: VButton,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleCancelActivity() {
      this.loading = true;
      this.$emit('cancel-activity', this.activity.payload.id, this.activity.assignedBy ? false : true);
    },
  },
};
</script>

