<template lang="pug">
dialer-layout
  template(#sider v-if="activeSider")
    .flex.px-2.w-full.h-8.items-center.justify-end
      .material-icons.notranslate.text-sm.text-gray-300.cursor-pointer(@click="closeSider", v-if="!isTransferring") close
    .flex.px-2.h-full.w-full.items-start(class="h-[25rem]")
      .flex.h-full.items-center.justify-center.pt-14(
        v-if="isKeyboardActive"
      )
        dialer-keyboard
      transfer-box(
        v-if="transferCall",
        :extra="extraCall",
      )

  template(#header)
    dialer-header Em chamada
  template(#body)
    audio(ref="remoteMedia", controls, v-show="false")
    .flex.flex-col.gap-8.items-center.justify-center.w-full(class='h-[25rem]', :class="{'opacity-60 pointer-events-none' : isTransferring}")
      .font-plus.text-white.font-semibold Em chamada...
      
      .flex.flex-col.gap-5
        .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.overflow-hidden
          avatar(
            v-if="!identityContact.unknown"
            :src="identityContact.avatarUrl",
            :avatarId="identityContact.id",
          )
          img.h-full(
            v-else
            src="@/assets/images/default_pfp.png"
          )

        .flex.flex-col.gap-0.items-center
          .text-white.font-semibold.font-plus {{ identityContact?.name }}
          .text-sm.text-slate-500.font-plus {{ callerNumber }}
          .text-sm.text-slate-500.font-plus(v-if="!identityContact?.unknown") {{ identityContact?.organization || "Sem organização" }}

        call-duration

        .flex.flex-col.gap-2(v-if="loadingHangUp")
          loading-ring

        .flex.flex-col.gap-2(v-else)
          .flex.gap-2
            dialer-option(
              :icon="muted ? 'mic_off' : 'mic'",
              @click="checkMute"
              :selected="muted"
              v-if="isWebRTC"
            )
              template(#tooltip)
                p.font-plus.text-white Ativar/Desativar microfone

            dialer-option(
              icon="pause",
              @click="holdCall",
              :selected="holdState == 'on'"
              v-if="isWebRTC"
            )
              template(#tooltip)
                p.font-plus.text-white Pausar/Despausar chamada

            dialer-option(
              icon="phone_forwarded",
              @click="openTransfer"
              :selected="transferCall"
              v-if="isWebRTC"
            ) 
              template(#tooltip)
                p.font-plus.text-white Abrir/Fechar aba de transferência

          .flex.gap-2
            dialer-option(
              icon="dialpad"
              @click="openKeyboard"
              :selected="isKeyboardActive"
              v-if="isWebRTC"
            ) 
              template(#tooltip)
                p.font-plus.text-white Abrir/Fechar teclado numérico

            dialer-option(
              red
              icon="call_end",
              @click="hangUp",
              v-if="isWebRTC"
            ) 
              template(#tooltip)
                p.font-plus.text-white Desligar chamada

            dialer-option(
              icon="perm_phone_msg",
              @click="sendToCsat",
              v-if="showCsatButton || queueCsat"
            ) 
              template(#tooltip)
                p.font-plus.text-white Enviar para CSAT
</template>

<script>
import CallDuration from '@/components/Dialer/dialer-call-duration.vue';
import DialerKeyboard from '@/components/Dialer/dialer-keyboard.vue';
import TransferBox from '@/components/Dialer/dialer-transfer-box.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import DialerHeader from '@/components/Dialer/dialer-header.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import SipMixin from '@/mixins/invite-calls.js';
import cservice from '@/services/cservice';
import EventBus from '@/main-event-bus';

export default {
  components: {
    DialerKeyboard,
    DialerLayout,
    CallDuration,
    DialerHeader,
    DialerOption,
    LoadingRing,
    TransferBox,
    Avatar,
  },
  mixins: [UserOrContactMixin, SipMixin],
  props: ['extra', 'data'],
  data() {
    return {
      selectedMate: '',
      holdState: 'off',
      inputValue: '',
      mute: 'off',
      isKeyboardActive: false,
      isSiderVisible: true,
      loadingHangUp: false,
      transferCall: false,
      transferred: false,
      isTheCaller: true,
      isCalling: false,
      failedImg: false,
      queueCsat: false,
      keyboard: false,
    };
  },
  computed: {
    isTransferring() {
      return this.$store.getters['sip/transferring'];
    },
    muted() {
      return this.$store.getters['sip/muted'];
    },
    showCsatButton() {
      return (
        this.$route.params.callId == this.queueData.linkedid &&
        this.queueData.queue_csat &&
        this.queueData.queue_csat === '1' &&
        this.isTheCaller
      );
    },
    extraCall() {
      return this.extra;
    },
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    queueData() {
      return this.$store.getters['cstate/GET_QUEUE_DATA'];
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    fromId() {
      return this.call?.fromId || this.call?.from_id;
    },
    isMineCall() {
      return this.fromId === this.user.id || this.call.from === this.user.endpoint;
    },
    callerId() {
      return this.isMineCall ? this.call.toId : this.fromId;
    },
    callerNumber() {
      return this.isMineCall ? this.call.to : this.call.from;
    },
    activeSider() {
      return this.isKeyboardActive || this.transferCall;
    },
    size() {
      return this.$store.getters['sip/size'];
    },
  },
  watch: {
    activeSider(val) {
      if (val && this.size.width <= 583) {
        this.$store.commit('sip/setSize', {
          width: this.size.width + 270,
          height: this.size.height,
        });
        this.$store.commit('sip/setActiveSider', true);
        return;
      }
      if (!val && this.size.width >= 583) {
        this.$store.commit('sip/setSize', {
          width: this.size.width - 270,
          height: this.size.height,
        });
        this.$store.commit('sip/setActiveSider', false);
        return;
      }
    },
    call(newPayload) {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    if (this.webphone) {
      this.createWebphoneAudioStream();
    }
  },
  methods: {
    openTransfer() {
      this.transferCall = !this.transferCall;
      this.isKeyboardActive = false;
    },
    openKeyboard() {
      this.isKeyboardActive = !this.isKeyboardActive;
      this.transferCall = false;
    },
    closeSider() {
      this.isKeyboardActive = false;
      this.transferCall = false;
    },
    handleClick() {
      EventBus.$emit('UX-event', {
        eventName: 'dialpad-in-call-mouse-event',
        payload: { event: 'click' },
      });
    },
    handleMouseEnter() {
      EventBus.$emit('UX-event', {
        eventName: 'dialpad-in-call-mouse-event',
        payload: { event: 'mouse-enter' },
      });
    },
    emmitSound(e) {
      const keyPressed = e.key;
      if (!this.session || !this.session.session) return;
      var options = {
        duration: 100,
        interToneGap: 70,
      };
      this.session.session.sessionDescriptionHandler.sendDtmf(keyPressed, options);
    },
    digitClicked(number) {
      this.inputValue = this.inputValue + number;
    },
    async sendToCsat() {
      await this._sendCsatCall(this.$route.params.callId, {
        techChannel: this.user.tech_channel,
        endpoint: this.user.endpoint,
      });
      EventBus.$emit('UX-event', {
        eventName: 'dialpad-sent-to-csat',
        payload: {
          techChannel: this.user.tech_channel,
          endpoint: this.user.endpoint,
        },
      });
      this.$emit('hangup-call');
    },
    async holdCall() {
      if (this.holdState == 'off') {
        this.holdState = 'on';
        this.hold();
      } else {
        this.holdState = 'off';
        this.unhold();
      }
    },
    async checkMute() {
      if (this.mute == 'off') {
        this.mute = 'on';
        this.$store.commit('sip/setMuted', true);
      } else {
        this.mute = 'off';
        this.$store.commit('sip/setMuted', false);
      }
      try {
        if (this.webphone && this.session) return this.muteSession(this.muted);
        await this.muteFunction(this.mute);
      } catch (error) {
        console.error(error);
        if (this.muted) return this.$toast.error('Não foi possível ativar o microfone');
        this.$toast.error('Não foi possível desativar o microfone');
      }
    },
    async hangUp() {
      if (this.loadingHangUp) return;
      this.loadingHangUp = true;
      try {
        await this._hangUpCall();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingHangUp = false;
        this.$emit('hangup-call');
      }
    },
    async getQueueCsat(id) {
      try {
        const res = await cservice.getCompanyQueues();
        const queue = res.find((queue) => queue.name === id);
        this.queueCsat = queue.csat === '1' ? true : false;
      } catch (error) {}
    },
    async initData() {
      this.queueCsat = false;

      this.setContactOrOperator({
        id: this.callerId,
        number: this.callerNumber,
      });

      if (this.queueData.queue) {
        await this.getQueueCsat(this.queueData.queue);
      }
    },
  },
};
</script>
