<template lang="pug">
portal(
  :to='outlet'
)
  .z-50.absolute.h-full.w-full.bg-black.bg-opacity-20.flex.justify-center.align-center(
    @click.self="$emit('click')"
  )
    slot
</template>

<script>
export default {
  props: {
    mo1: Boolean,
    mo2: Boolean,
  },
  computed: {
    outlet() {
      if (this.mo1) return 'modal-outlet';
      if (this.mo2) return 'modal-outlet-2';
      return 'modal-outlet';
    },
  },
};
</script>
