<template lang="pug">
section.w-full
  span.flex.items-center.justify-start.mb-4.gap-4
    date-picker(
      class="w-1/4"
      @date-pick="handleDateFilter"
      @reset-filter="resetDateFilter"
      mode="dateTime"
    )

    input(
      type="text"
      class="w-2/5 bg-[#f8f9fa] border border-solid border-[#e9ecef] rounded h-12 indent-3 text-base font-inter placeholder:text-gray-300 py-2 px-1 focus:bg-white focus:border-[#aaa] transition-all duration-300 ease-linear"
      v-model="protocol"
      @input="filterByProtocol"
      placeholder="Filtrar por protocolo"
    )

    v-button-secondary(
      class="font-inter flex ml-auto"
      @click="isFiltersModalOpen = true"
    )
      span.text-brand.material-icons.notranslate.mr-2(class="dark:invert-0") filter_list
      span.text-base Mais filtros

    modal(@click="isFiltersModalOpen = false" v-if="isFiltersModalOpen")
      modal-body(class="min-w-[50%]")
        template(v-slot:header)
          .flex.justify-between
            h4.text-2xl.text-gray-500.font-semibold.font-inter Filtros avançados
            v-button-secondary(@click="resetAdvancedFilters")
              span.text-base Limpar filtros avançados
        template(v-slot:body)
          section.flex.flex-col.w-full.gap-4(class="overflow-y-auto max-h-[65vh]")
            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.py-2 Eventos
              section.grid.grid-cols-2.gap-y-6.gap-x-2.justify-items-start
                span.flex.items-center.gap-2
                  input.accent-brand.w-4.h-4.cursor-pointer(type="checkbox" value="finishedSilently" id="finishedSilently" v-model="advancedFilters.finishedSilently")
                  label.text-base.text-gray-500.font-inter.pt-0.mb-0.cursor-pointer(for="finishedSilently")
                    | Finalizados silenciosamente
                span.flex.items-center.gap-2(v-for="event in statusFilterOptions")
                  input.accent-brand.w-4.h-4.cursor-pointer(type="checkbox" :value="event.value" :id="event.value" v-model="advancedFilters.statusFilter")
                  label.text-base.text-gray-500.font-inter.pt-0.mb-0.cursor-pointer(:for="event.value")
                    | {{ event.label }}

            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.py-2 Tipo de Chat
              section.grid.grid-cols-2.gap-y-6.gap-x-16.justify-items-start
                span.flex.items-center.gap-2(v-for="type in typeFilterOptions")
                  input.accent-brand.w-4.h-4.cursor-pointer(type="checkbox" :value="type.value" :id="type.value" v-model="advancedFilters.typeFilter")
                  label.text-base.text-gray-500.font-inter.pt-0.mb-0.cursor-pointer(:for="type.value")
                    | {{ type.label }}

            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.py-2 Transferências
              section.grid.grid-cols-2.gap-y-6.gap-x-16.justify-items-start
                span.flex.items-center.gap-2
                  input.accent-brand.w-4.h-4.cursor-pointer(type="checkbox" value="transferred" id="transferred" v-model="advancedFilters.transferred")
                  label.text-base.text-gray-500.font-inter.pt-0.mb-0.cursor-pointer(for="transferred")
                    | Chats Transferidos

            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Filtrar por contato
              contacts-dropdown(
                :pre-selected-contacts="advancedFilters.createdBy"
                @selected-contacts-list="handleSelectedContacts"
              )
                template(#search="{search}")
                  .flex
                    v-input(
                      @input="search"
                      :label="'Nome do contato'"
                    )
                template(#items="{contact, selected}")
                  .flex
                    contact-phone-row(
                      :data="contact"
                      :selected="selected"
                    )
                template(#options="{selectAll, deselectAll}")
                  .flex.flex-col.gap-2.w-full
                    .flex.gap-2
                      v-button-secondary.w-full(
                        small
                        @click="deselectAll"
                      ) Desmarcar todos
                      v-button-secondary.w-full(
                        small
                        @click="selectAll"
                      ) Selecionar todos

            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Filtrar por tags
              tags-dropdown(
                :pre-selected-tags="advancedFilters.tagIds"
                @selected-tags-list="handleSelectedTags"
              )
                template(#search="{search}")
                  .flex
                    v-input(
                      @input="search"
                      :label="'Nome da tag'"
                    )
                template(#items="{tag, selected}")
                  .flex.w-full.items-center.justify-between.p-2(
                    class="hover:bg-gray-100 cursor-pointer transition-all"
                  )
                    p.text-base {{ tag.text }}
                    .flex.rounded-full.bg-brand-400.p-1.items-center.justify-center.w-6(v-if="selected")
                      .material-icons.text-xs.notranslate.text-white done

                template(#options="{selectAll, deselectAll}")
                  .flex.flex-col.gap-2.w-full
                    .flex.gap-2
                      v-button-secondary.w-full(
                        small
                        @click="deselectAll"
                      ) Desmarcar todas
                      v-button-secondary.w-full(
                        small
                        @click="selectAll"
                      ) Selecionar todas

            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Filtrar por provedor
              providers-dropdown(
                :pre-selected-providers="advancedFilters.provider"
                @selected-providers-list="handleSelectedProviders"
              )
                template(#search="{search}")
                  .flex
                    v-input(
                      @input="search"
                      :label="'Nome do provedor'"
                    )
                template(#items="{provider, selected}")
                  .flex.w-full.items-center.justify-between.p-2(
                    class="hover:bg-gray-100 cursor-pointer transition-all"
                  )
                    p.text-base {{ provider }}
                    .flex.rounded-full.bg-brand-400.p-1.items-center.justify-center.w-6(v-if="selected")
                      .material-icons.text-xs.notranslate.text-white done

                template(#options="{selectAll, deselectAll}")
                  .flex.flex-col.gap-2.w-full
                    .flex.gap-2
                      v-button-secondary.w-full(
                        small
                        @click="deselectAll"
                      ) Desmarcar todos
                      v-button-secondary.w-full(
                        small
                        @click="selectAll"
                      ) Selecionar todos

        template(v-slot:footer)
          .flex.gap-4
            v-button-secondary(type="button" @click="isFiltersModalOpen = false") Fechar
            v-button-primary(type="button" @click="applyAdvancedFilters") Aplicar

  .w-full.mt-8(v-if="loading")
    content-placeholders.w-full.mb-8.ml-4(
      v-for="item in 10"
      :key="item"
      :rounded="true"
    )
      content-placeholders-img.w-full.h-14

  .w-full.pb-16(v-else)
    historic-table(:chats="chats")

  observer.py-1(v-if="hasMoreChats" @intersect="getMoreChats")
  loading-ring(v-if="loadingMoreChats")
</template>

<script>
import VHeading from '@/components/Text/v-heading.vue';
import HistoricTable from './Callbacks.HistoricTable.vue';
import DatePicker from '@/components/DatePicker.vue';
import ContactsDropdown from '@/components/Dropdown/contact-phone-multi-selection-dropdown.vue';
import ContactPhoneRow from '@/components/TablesRows/contact-phone-row.vue';
import TagsDropdown from '@/components/Dropdown/tags-multi-selection-dropdown.vue';
import ProvidersDropdown from '@/components/Dropdown/provider-multi-selection-dropdown.vue';
import VInput from '@/components/Inputs/IconInput.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButton from '@/components/Buttons/v-button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import Observer from '@/components/Observer.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import { getChatsAnalytical } from '@/services/chat';
import { debounce } from '@/helpers';

export default {
  components: {
    HistoricTable,
    DatePicker,
    ContactsDropdown,
    ContactPhoneRow,
    TagsDropdown,
    VInput,
    VButtonSecondary,
    Modal,
    ModalBody,
    VButtonPrimary: VButton,
    VHeading,
    ProvidersDropdown,
    Observer,
    LoadingRing,
  },
  data() {
    return {
      loading: false,
      isFiltersModalOpen: false,
      chats: [],
      createdAtGte: new Date('2000-01-01T00:00:00'),
      createdAtLte: new Date('3000-01-01T00:00:00'),
      createdBy: [],
      tagIds: [],
      provider: [],
      protocol: '',
      finishedSilently: null,
      transferred: null,
      status: [],
      type: [],
      currentScore: null,

      loadingMoreChats: false,
      hasMoreChats: true,

      advancedFilters: {
        createdBy: {},
        tagIds: {},
        provider: [],
        statusFilter: [],
        typeFilter: [],
        finishedSilently: false,
        transferred: false,
      },

      statusFilterOptions: [
        {
          value: 'terminated',
          label: 'Finalizados pelo operador',
        },
        {
          value: 'expired',
          label: 'Finalizados pelo sistema',
        },
        {
          value: 'completed',
          label: 'Finalizados por autoatendimento',
        },
      ],

      typeFilterOptions: [
        {
          value: 'inbound',
          label: 'Entrada',
        },
        {
          value: 'outbound',
          label: 'Saída',
        },
      ],
    };
  },
  methods: {
    filterByProtocol: debounce(async function () {
      await this.fetchChats();
    }, 500),
    async applyAdvancedFilters() {
      this.createdBy = Object.keys(this.advancedFilters.createdBy);
      this.tagIds = Object.keys(this.advancedFilters.tagIds);
      this.provider = this.advancedFilters.provider;
      this.finishedSilently = this.advancedFilters.finishedSilently;
      this.transferred = this.advancedFilters.transferred;
      this.status = this.advancedFilters.statusFilter;
      this.type = this.advancedFilters.typeFilter;

      this.isFiltersModalOpen = false;

      await this.fetchChats();
    },
    handleSelectedContacts(data) {
      this.advancedFilters.createdBy = data;
    },
    handleSelectedTags(data) {
      this.advancedFilters.tagIds = data;
    },
    handleSelectedProviders(data) {
      this.advancedFilters.provider = data;
    },
    async resetAdvancedFilters() {
      this.advancedFilters.createdBy = {};
      this.createdBy = [];

      this.advancedFilters.tagIds = {};
      this.tagIds = [];

      this.advancedFilters.provider = [];
      this.provider = [];

      this.advancedFilters.finishedSilently = false;
      this.finishedSilently = null;

      this.advancedFilters.transferred = false;
      this.transferred = null;

      this.advancedFilters.statusFilter = [];
      this.status = [];

      this.advancedFilters.typeFilter = [];
      this.type = [];

      this.isFiltersModalOpen = false;
      await this.fetchChats();
    },
    async handleDateFilter(date) {
      this.createdAtGte = date.start;
      this.createdAtLte = date.end;

      await this.fetchChats();
    },
    async resetDateFilter() {
      this.createdAtGte = new Date('2000-01-01T00:00:00');
      this.createdAtLte = new Date('3000-01-01T00:00:00');

      await this.fetchChats();
    },
    async fetchChats() {
      this.loading = true;

      try {
        this.currentScore = null;

        const { data, currentScore, total } = await this.getChats();

        this.currentScore = currentScore;
        this.chats = data;
        this.hasMoreChats = this.chats.length < total;
      } catch (error) {
        this.$toast.error('Erro ao carregar chats');
      } finally {
        this.loading = false;
      }
    },
    async getMoreChats() {
      this.loadingMoreChats = true;

      try {
        const { data, currentScore, total } = await this.getChats();

        this.currentScore = currentScore;
        this.chats = [...this.chats, ...data];
        this.hasMoreChats = this.chats.length < total;
      } catch (error) {
        this.$toast.error('Erro ao carregar mais chats');
      } finally {
        this.loadingMoreChats = false;
      }
    },
    async getChats() {
      const payload = {
        operatorsIds: [this.user.id],
        companyId: this.user.company_id,
        provider: this.chatProviders.split(','),
        createdAtGte: this.createdAtGte,
        createdAtLte: this.createdAtLte,
        createdBy: this.createdBy,
        tags: this.tagIds,
        provider: this.provider,
        status: this.status,
        type: this.type,
        searchAfter: [this.currentScore],
      };

      if (this.protocol.length) payload.protocol = this.protocol;
      if (this.finishedSilently) payload.finishedSilently = this.finishedSilently;
      if (this.transferred) payload.transferred = this.transferred;

      const result = await getChatsAnalytical(payload);

      return result;
    },
  },
  computed: {
    chatProviders() {
      return process.env.VUE_APP_CHAT_PROVIDERS;
    },
  },
  async created() {
    await this.fetchChats();
  },
};
</script>
