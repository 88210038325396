<template lang="pug">
overlay
  template(v-slot:header)
    header.w-full.border-b.p-6.flex.gap-4.items-center.justify-between
      h1.text-xl.text-zinc-800.font-plus.font-bold Adicionar contato
      close-button(@click="$emit('close-overlay')")
  template(#content)
    div.h-full.w-full(ref="overlay-content")
      add-contact-content.pr-12(:openedByContext="openedByContext" :is-overlay="true", @close-overlay="$emit('close-overlay')")
</template>

<script>
import AddContactContent from '../Contacts/AddContactContent.vue';
import overlay from '@/components/Overlay/overlay.vue';
import CloseButton from '@/components/Buttons/v-close-button.vue';

export default {
  title: 'Opens - Adicionar contato',
  props: {
    openedByContext: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddContactContent,
    CloseButton,
    overlay,
  },
};
</script>
