<template lang="pug">
.w-full.relative
  .flex.flex-col.mb-2.items-start
    p.font-semibold.text-gray-500.font-inter(v-if="label") {{ label }}
    p.text-xs.text-gray-500(v-if="description") {{ description }}
  v-dropdown.w-full(placement="bottom", autoSize="min")
    .w-full.border.border-gray-200.rounded-md.flex.justify-between.py-3.font-inter.cursor-pointer(
      class="bg-[#F8F9FA]"
    )
      p.ml-5.text-base {{ Object.keys(tagsMap).length ? `(${Object.keys(tagsMap).length}) Tag(s) selecionada(s)` : 'Selecione uma ou mais tags' }}
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    template(#popper)
      .p-2
        slot(
          name="search"
          :search="handleSearchTag"
        )
        .flex.flex-col.max-h-60.overflow-y-scroll.mt-2.items-start.justify-start
          div.w-full(
            v-for="tag in tags",
            :key="tag.id",
            @click="select(tag)"
          )
            slot(
              name="items",
              :tag="tag",
              :selected="tagsMap[tag.id]?.id == tag.id"
            )
          .flex.p-2(v-if="loading")
            loading-ring
          observer(
            v-else
            @intersect="loadMoreTags"
          )
          p.font-plus.text-sm.text-center.py-2.text-gray-500(v-if="!tags.length") Nenhuma tag encontrada
        .flex.w-full.justify-center.gap-2.pt-2
          slot(
            name="options"
            :selectAll="selectAll"
            :deselectAll="deselectAll"
          )
</template>

<script>
import { hideAllPoppers, Dropdown } from 'floating-vue';
import rhService from '@/services/rship.js';
import LoadingRing from '../LoadingRing.vue';
import Observer from '../Observer.vue';
import { debounce } from '@/helpers';

export default {
  props: {
    description: { type: String, default: '' },
    label: { type: String, default: '' },
    preSelectedTags: { type: Object, default: () => ({}) },
  },
  components: {
    vDropdown: Dropdown,
    LoadingRing,
    Observer,
  },
  data() {
    return {
      search: '',
      lastRequestLength: 0,
      offset: 0,
      loading: false,
      selection: [],
      tags: [],
      tagsMap: {},
    };
  },
  watch: {
    customPayload() {
      this.searchTag();
    },
    tagsMap(val) {
      return this.$emit('selected-tags-list', val);
    },
    search() {
      this.searchTag();
    },
  },
  methods: {
    searchTag: debounce(async function () {
      this.tags = [];
      this.offset = 0;
      this.lastRequestLength = 0;
      await this.getTags();
    }, 500),
    async loadMoreTags() {
      if (this.lastRequestLength < 20) return;
      this.offset = this.offset + 20;
      await this.getTags();
    },
    async getTags() {
      if (this.loading) return;

      this.loading = true;

      try {
        const payload = {
          companyId: this.user.company_id,
          orderBy: 'text',
          limit: 20,
          offset: this.offset,
          ...this.customPayload,
        };

        if (this.search) {
          payload.text = this.search.toLowerCase();
        }

        const { items: tags } = await rhService.getTagsPaginated(payload);

        this.tags = [...this.tags, ...tags];
        this.lastRequestLength = tags?.length;
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível carregar as tags');
      } finally {
        this.loading = false;
      }
    },
    select(tag) {
      const tagsMapCopy = { ...this.tagsMap };

      if (!this.tagsMap[tag.id]) tagsMapCopy[tag.id] = { id: tag.id, text: tag.text };

      if (this.tagsMap[tag.id]) delete tagsMapCopy[tag.id];

      return (this.tagsMap = tagsMapCopy);
    },
    selectAll() {
      this.deselectAll();
      for (const tag of this.tags) {
        this.select(tag);
      }
    },
    deselectAll() {
      this.tagsMap = {};
    },
    handleSearchTag(input) {
      this.search = input;
    },
  },
  async created() {
    this.tagsMap = this.preSelectedTags;
    await this.getTags();
  },
};
</script>
