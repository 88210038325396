import { historyMultiIndexSearch } from '@/services/call-report';

const DEFAULT_CHAT_PROVIDERS = ['direct', 'opens', 'yunique', 'messenger', 'whatsapp'];

const state = {
  multiIndexHistory: [],
  filters: {
    companyId: null,
    limit: 10,
    searchAfter: null,
    indexes: [],
    contactIds: [],
    operatorIds: [],
    organizationIds: [],
    chatProviders: DEFAULT_CHAT_PROVIDERS,
    chatStatus: [],
  },
  loading: false,
  hasMore: false,
};

const mutations = {
  setMultiIndexHistory(state, history) {
    state.multiIndexHistory = history;
  },
  appendMultiIndexHistory(state, history) {
    state.multiIndexHistory = [...state.multiIndexHistory, ...history];
  },
  setFilters(state, filters) {
    const newFilters = { ...filters };
    if (newFilters.indexes && !newFilters.indexes.includes('chat')) {
      state.filters.chatProviders = undefined;
    } else if (newFilters.indexes?.includes('chat')) {
      state.filters.chatProviders = DEFAULT_CHAT_PROVIDERS;
    }

    state.filters = { ...state.filters, ...newFilters };
  },
  resetFilters(state, baseFilters = {}) {
    const resetFilters = {
      companyId: null,
      limit: 10,
      searchAfter: null,
      indexes: [],
      contactIds: [],
      operatorIds: [],
      organizationIds: [],
      chatStatus: [],
      ...baseFilters,
    };

    // Only add chatProviders if chat is in indexes
    if (baseFilters.indexes?.includes('chat')) {
      resetFilters.chatProviders = DEFAULT_CHAT_PROVIDERS;
    }

    state.filters = resetFilters;
    state.multiIndexHistory = [];
    state.hasMore = false;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setHasMore(state, hasMore) {
    state.hasMore = hasMore;
  },
};

const actions = {
  async fetchMultiIndexHistory({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await historyMultiIndexSearch(state.filters);
      commit('setMultiIndexHistory', response.data);
      commit('setHasMore', !!response.currentScore);
      if (response.currentScore) {
        commit('setFilters', { searchAfter: response.currentScore });
      }
    } catch (error) {
      console.error('Error fetching multi-index history:', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchMoreMultiIndexHistory({ commit, state }) {
    if (!state.hasMore || state.loading) return;

    try {
      commit('setLoading', true);
      const response = await historyMultiIndexSearch(state.filters);
      commit('appendMultiIndexHistory', response.data);
      commit('setHasMore', !!response.currentScore);
      if (response.currentScore) {
        commit('setFilters', { searchAfter: response.currentScore });
      }
    } catch (error) {
      console.error('Error fetching more multi-index history:', error);
    } finally {
      commit('setLoading', false);
    }
  },
  setFilters({ commit }, filters) {
    commit('setFilters', filters);
  },
  resetFilters({ commit }, baseFilters) {
    commit('resetFilters', baseFilters);
  },
};

const getters = {
  getMultiIndexHistory: (state) => state.multiIndexHistory,
  getFilters: (state) => state.filters,
  isLoading: (state) => state.loading,
  hasMoreData: (state) => state.hasMore,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
