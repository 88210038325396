<template lang="pug">
.context-area-left-side
  UserContactBox(
    v-if="!eventDetail",
    @open-overlay="emitOverlay",
    @open-callback="openCallback"
  )
  div(
    v-if="$store.getters['rh/GET_PROTOCOL']",
    :class="'protocol-aligner ' + (!eventDetail ? 'protocol-margin' : '')"
  )
    a.protocol-text Protocolo
    span.protocol-number-text(ref="protocol-text") {{ $store.getters['rh/GET_PROTOCOL'] }}
    span.protocol-link-icon.material-icons(
      title="Copiar protocolo",
      @click="copyProtocol()"
    ) &#xe157
    span.copied-alert(ref="copy-alert") Copiado!
  v-dropdown.w-44(placement="bottom" :class="{'mt-4': !eventDetail}")
    button(class="flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none group hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10")
      .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") Ações
      span(class="text-gray-400 material-icons notranslate group-hover:text-brand") keyboard_arrow_down
    template(#popper)
      ul(class="w-full divide-y")
        dropdown-item(@click="openTagModal" label="Adicionar tag" icon="f05b")
        dropdown-item(@click="openModal('NotesChat')" label="Adicionar anotação" icon="e873")
        dropdown-item(v-if="plan.cases" @click="openCaseModal()" label="Vincular Caso" icon="e85d")
  .flex.flex-col.items-start
    label.text Tags
    v-button-secondary(@click="openTagModal") Adicionar Tag
  .w-full.flex.flex-wrap.justify-start
    call-tag.mr-2.my-2(@tag-removed='populateTags' v-for="(data, index) in tags" :key="data.id + data.text" :call-tag='getCompleteTag(data)')
    .text-gray-400.font-inter(v-show="!tags[0]") Nenhuma tag nesta atividade
  simple-accordion.mt-4(title="Movidesk" :isOpen="true" v-if="movideskEnabled")
    template(v-slot:body)
      div.py-2.px-3
        MovideskIdentifyClient(
          v-if="!movideskClientSelected",
          :movideskToken="movideskToken",
          @movideskConfirmClient="movideskConfirmClient"
        )
        MovideskClientOption(
          v-if="movideskClientSelected",
          @open-overlay="emitOverlay",
          :movideskToken="movideskToken",
          :client="movideskClientSelected"
        )
  slot(name='context-area-warning')
  .fields-aligner
    slot(name="audio-player")
</template>

<script>
import AudioPlayer from '@/components/Player/audio-player.vue';
import TagInput from '@/components/Inputs/TagInput.vue';
import MovideskIdentifyClient from '@/components/Movidesk/IdentifyClientComponent.vue';
import MovideskClientOption from '@/components/Movidesk/ClientOptionComponent.vue';
import CallTag from '@/components/CallEventTracking/call-tag.vue';
import UserContactBox from './ContextArea.UserContactBox.vue';
import integrationService from '@/services/integrations';
import SimpleAccordion from '@/components/Accordions/SimpleAccordion.vue';
import { Dropdown, hideAllPoppers } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import rship from '@/services/rship';
import io from 'socket.io-client';
import { getAllCallTags } from '@/services/call-report';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';

export default {
  data() {
    return {
      noteValue: '',
      tagValue: '',
      tags: [],
      companyTags: [],
      movideskEnabled: false,
      movideskClientSelected: false,
      movideskToken: '',
      callAudio: undefined,
    };
  },
  props: ['eventDetail', 'callData'],
  components: {
    AudioPlayer,
    UserContactBox,
    TagInput,
    MovideskIdentifyClient,
    MovideskClientOption,
    vButtonSecondary,
    SimpleAccordion,
    VDropdown: Dropdown,
    DropdownItem,
    CallTag,
  },
  methods: {
    getCompleteTag(tag) {
      return {
        ...tag,
        text: this.tagsInfo.find((tagInfo) => tagInfo.id === tag.tagId).text,
        callId: this.call.linkedid || this.call.uniqueid,
      };
    },
    openCaseModal() {
      this.$store.commit('modal/setActiveModal', { name: 'linkCase', context: { contact: this.contact } });
      hideAllPoppers();
    },
    openTagModal() {
      this.$store.commit('modal/setActiveModal', 'TagChat');
      this.$store.commit('modal/setContext', {
        callId: this.linkedid || this.call.callid,
        linkedid: this.call?.linkedid || this.call?.uniqueid,
        type: 'call',
      });
      hideAllPoppers();
    },
    async populateTags() {
      const linkedId = this.call.linkedid || this.call.uniqueid;
      if (!linkedId) return;
      this.tags = await getAllCallTags({
        companyId: this.user.company_id,
        linkedId,
      });
    },
    openModal(type) {
      this.$store.commit('modal/setActiveModal', type);
      this.$store.commit('modal/setContext', {
        callId: this.call.linkedid || this.call.uniqueid,
        type: 'call',
      });
      hideAllPoppers();
    },
    openCallback() {
      if (this.$store.getters['rh/GET_CONTACT'].name || this.$store.getters['rh/GET_CONTACT'].username) {
        let contact = this.$store.getters['rh/GET_CONTACT'];
        this.$emit('open-callback', contact);
      } else {
        this.$emit('open-callback', {
          number: this.$store.getters['rh/GET_PHONENUMBER'],
        });
      }
    },
    clearData() {
      this.noteValue = '';
      this.movideskEnabled = false;
      this.movideskClientSelected = false;
      this.movideskToken = '';
      this.tags = [];
      this.tagValue = '';
      this.socket.disconnect();
      this.socketConfig();
      this.setupMoviDeskIntegration();
    },
    copyProtocol() {
      let input = document.body.appendChild(document.createElement('input'));
      input.value = this.$store.getters['rh/GET_PROTOCOL'];
      input.select();
      document.execCommand('copy');
      this.$refs['copy-alert'].style.display = 'flex';
      setTimeout(() => (this.$refs['copy-alert'].style.opacity = 1), 1);
      setTimeout(() => (this.$refs['copy-alert'].style.opacity = 0), 800);
      setTimeout(() => (this.$refs['copy-alert'].style.display = 'none'), 1100);
      input.parentNode.removeChild(input);
    },
    emitOverlay(payload) {
      this.$emit('open-overlay', payload);
    },
    recoveryNote() {
      try {
        this.noteValue = window.localStorage.getItem(this.$store.getters['cstate/GET_CALL_PAYLOAD'].linkedid) || '';
        this.$emit('note-event', this.noteValue);
      } catch (error) {
        this.$toast.open({ type: 'error', message: 'Falha ao recuperar anotações...' });
      }
    },
    isWorkGroupAllowed(movideskIntegration) {
      if (!movideskIntegration.allowedWorkGroup) return false;
      if (!this.user.users_bonds) return false;
      for (const bond of this.user.users_bonds) {
        if (bond.group_id === movideskIntegration.allowedWorkGroup) {
          return true;
        }
      }
      return false;
    },
    movideskConfirmClient(movideskClientSelected) {
      this.movideskClientSelected = movideskClientSelected;
    },
    socketConfig() {
      this.socket = io(`${this.requestLinks.rhistory}`, {
        query: {
          callId: this.linkedid || this.call.linkedid || this.call.uniqueid,
        },
        reconnect: true,
      });

      this.socket.on('rh-update', (payload) => {
        switch (payload.eventType) {
          case 'protocol':
            {
              this.$store.commit('rh/SET_PROTOCOL', payload.identifier);
            }
            break;
          default: {
            this.populateTags();
          }
        }
      });
      this.socket.on('rh-delete', (payload) => {
        this.populateTags();
      });
    },
    async setupMoviDeskIntegration() {
      try {
        const r = await integrationService.verifyMovidesk({
          companyId: this.user.company_id,
        });
        this.$store.commit('SET_MOVIDESK', r);
        const isUserAllowed = this.isWorkGroupAllowed(r);
        if (r && r.active && isUserAllowed) {
          this.movideskToken = r.token;
          this.movideskEnabled = true;
        }
      } catch (error) {}
    },
  },
  async created() {
    await this.setupMoviDeskIntegration();
    this.tagsInfo = await rship.getTags(this.user.company_id);
    this.populateTags();
    this.socketConfig();
    if (!this.$store.getters['cstate/GET_CALL_PAYLOAD']) return;
  },
  computed: {
    contact() {
      const contact = this.$store.getters['rh/GET_CONTACT'];
      contact.number = this.phonenumber;
      return contact;
    },
    linkedid() {
      return this.$route.params.callId;
    },
    call() {
      return this.callData || this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
  },
  watch: {
    tags(value) {
      this.$emit('tag-event', value);
    },
    noteValue(value) {
      window.localStorage.setItem(this.$store.getters['cstate/GET_CALL_PAYLOAD'].linkedid, value);
    },
    linkedid() {
      this.clearData();
    },
  },
};
</script>

<style>
.movidesk-area-identify {
  margin-top: 2px;
  width: 100%;
  height: 300px;
  padding-left: 20px;
  color: #868e96;
}
.movidesk-area-option {
  margin-top: 20px;
  width: 100%;
  height: 200px;
  padding-left: 20px;
  color: #868e96;
}
.movidesk-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #495057;
  padding-top: 20px;
  padding-bottom: 20px;
}
.movidesk-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #868e96;
}
.movidesk-text-bold {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #495057;
}
.movidesk-divider {
  margin: 20px;
  border-bottom: 1px solid #f2f3f5;
}
.movidesk-action {
  text-align: center;
  font-weight: bold;
}
.movidesk-subtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #868e96;
  padding-top: 20px;
  padding-bottom: 20px;
}
.movidesk-right {
  float: right;
  padding-right: 10px;
}
.movidesk-button {
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s !important;
  color: var(--brand-color) !important;
}
.movidesk-button:hover {
  opacity: 0.4;
}
.context-area-left-side {
  width: 40%;
  min-width: 395px;
}
.audio-progress-range {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background: #e9ecef;
}
.audio-progress-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: none;
}
.audio-progress-range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: none;
}
.audio-duration-content {
  margin-top: 4px;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
}
.audio-duration-left {
  width: 50%;
}
.audio-duration-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.audio-progress-content {
  position: relative;
  width: 87%;
  height: 100%;
  display: flex;
  margin-left: 12px;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #868e96;
  padding-top: 10px;
}
.audio-progress {
  position: absolute;
  height: 4px;
  pointer-events: none;
  width: 0px;
  background: var(--brand-color);
}
.audio-progress-bar {
  width: 100%;
  position: relative;
  height: 4px;
  background: #e9ecef;
}
.player-box {
  width: 100%;
  margin-top: 8px;
  padding-left: 14px;
  padding-top: 11px;
  display: flex;
  flex-direction: row;
  height: 48px;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
}
.play-icon {
  font-size: 24px !important;
  color: var(--brand-color);
}
.play-button {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.copied-alert {
  display: none;
  position: absolute;
  margin-left: 300px;
  opacity: 0;
  padding: 10px;
  border-radius: 4px;
  background-color: #343a40;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 14px;
  transition: 0.3s;
  color: #fff;
}
.fields-aligner {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}
.tag-input {
  width: 100%;
  margin-top: 8px;
}
.note-context-input {
  width: 100%;
  height: 125px !important;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  margin-top: 8px !important;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px !important;
  transition: border 0.3s, background 0.3s, resize 0s !important;
}
.note-context-input::placeholder {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #868e96;
}
.protocol-aligner {
  display: flex;
  align-items: center;
}
.input-default-margin {
  margin-top: 20px;
}
.protocol-margin {
  margin-top: 24px;
}
.protocol-link-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 20px !important;
  color: var(--brand-color);
  transition: 0.2s;
  transform: scale(1.05);
}
.protocol-link-icon:hover {
  color: #eb9f89;
}
.protocol-text:hover {
  color: #868e96;
}
.protocol-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #868e96;
}
.protocol-number-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-left: 10px;
  color: #343a40;
}
</style>
