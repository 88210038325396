<template lang="pug">
section.w-full.mb-16
  finish-activity-modal(
    @finish-activity="handleFinishCallback"
    @close-modal="isFinishActivityModalOpen = false"
    :activity="activity"
    v-if="isFinishActivityModalOpen"
  )

  cancel-activity-modal(
    @cancel-activity="handleCancelCallback"
    @close-modal="isCancelActivityModalOpen = false"
    :activity="activity"
    v-if="isCancelActivityModalOpen"
  )

  span.flex.items-center.justify-start.mb-4.gap-4
    date-picker(
      class="w-2/6"
      @date-pick="handleDateFilter"
      @reset-filter="resetDateFilter"
      mode="dateTime"
    )

    input(
      type="text"
      class="w-2/6 bg-[#f8f9fa] border border-solid border-[#e9ecef] rounded h-12 indent-3 text-base font-inter placeholder:text-gray-300 py-2 px-1 focus:bg-white focus:border-[#aaa] transition-all duration-300 ease-linear"
      v-model="searchContactNumber"
      @input="filterByContactNumber"
      placeholder="Filtrar por número de contato"
    )

    v-button-secondary(
      class="flex ml-auto font-inter"
      @click="isFiltersModalOpen = true"
    )
      span.text-brand.material-icons.notranslate.mr-2(class="dark:invert-0") filter_list
      span.text-base Mais filtros

    modal(@click="isFiltersModalOpen = false" v-if="isFiltersModalOpen")
      modal-body(class="min-w-[50%]")
        template(v-slot:header)
          .flex.justify-between
            h4.text-2xl.text-gray-500.font-semibold.font-inter Filtros avançados
            v-button-secondary(@click="resetAdvancedFilters")
              span.text-base Limpar filtros avançados

        template(v-slot:body)
          section.flex.flex-col.w-full.gap-4(class="overflow-y-auto max-h-[65vh]")
            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Filtrar por contato
              contacts-dropdown(
                :pre-selected-contacts="advancedFilters.contacts"
                @selected-contacts-list="handleSelectedContacts"
              )
                template(#search="{search}")
                  .flex
                    v-input(
                      @input="search"
                      :label="'Nome do contato'"
                    )
                template(#items="{contact, selected}")
                  .flex
                    contact-phone-row(
                      :data="contact"
                      :selected="selected"
                    )
                template(#options="{selectAll, deselectAll}")
                  .flex.flex-col.gap-2.w-full
                    .flex.gap-2
                      v-button-secondary.w-full(
                        small
                        @click="deselectAll"
                      ) Desmarcar todos
                      v-button-secondary.w-full(
                        small
                        @click="selectAll"
                      ) Selecionar todos

            section.flex.flex-col.w-full.items-start
              h2.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Filtrar por fila
              queues-dropdown(
                :pre-selected-queues="advancedFilters.queues"
                @selected-queues-list="handleSelectedQueues"
              )
                template(#search="{search}")
                  .flex
                    v-input(
                      @input="search"
                      :label="'Nome da fila'"
                    )
                template(#items="{queue, selected}")
                  .flex.w-full.items-center.justify-between.p-2(
                    class="transition-all cursor-pointer hover:bg-gray-100"
                  )
                    p.text-base {{ queue }}
                    .flex.rounded-full.bg-brand-400.p-1.items-center.justify-center.w-6(v-if="selected")
                      .material-icons.text-xs.notranslate.text-white done

                template(#options="{selectAll, deselectAll}")
                  .flex.flex-col.gap-2.w-full
                    .flex.gap-2
                      v-button-secondary.w-full(
                        small
                        @click="deselectAll"
                      ) Desmarcar todas
                      v-button-secondary.w-full(
                        small
                        @click="selectAll"
                      ) Selecionar todas

        template(v-slot:footer)
          .flex.gap-4
            v-button-secondary(type="button" @click="isFiltersModalOpen = false") Fechar
            v-button-primary(type="button" @click="applyAdvancedFilters") Aplicar

  .w-full.mt-8(v-if="loading")
    content-placeholders.w-full.mb-8.ml-4(
      v-for="item in 10"
      :key="item"
      :rounded="true"
    )
      content-placeholders-img.w-full.h-14

  table.leading-normal.text-left.border-collapse.w-full.font-inter.font-normal.text-sm.text-gray-600(v-else)
    thead.w-full.h-14.bg-gray-100.rounded.font-inter.font-semibold.text-sm.leading-5.text-gray-600
      tr
        th.pl-4.h-14(role='columnheader' scope='col') Origem
        th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role="columnheader" scope="col") Ligar para
        th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role="columnheader" scope="col") Observações
        th.mdc-data-table__header-cell(role="columnheader" scope="col") Adicionado
        th.mdc-data-table__header-cell(role="columnheader" scope="col") Ações

    transition-group.mdc-data-table__content(
      tag="tbody"
      name="slide-fade"
      :duration="200"
      mode="out-in"
    )
      abandon-activities(
        v-for="data in callbacks"
        :key="data.id"
        :data="data"
        @openOverlay="openOverlay"
        @calling="calling"
        @finish-activity="finishActivity"
        @cancel-activity="cancelActivity"
      )

  observer.py-1(v-if="hasMoreCallbacks" @intersect="fetchMoreCallbacks")
  loading-ring(v-if="loadingMoreCallbacks")
</template>

<script>
import AbandonActivities from './Callbacks.Abandon.vue';
import Observer from '@/components/Observer.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import DatePicker from '@/components/DatePicker.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButton from '@/components/Buttons/v-button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import ContactsDropdown from '@/components/Dropdown/contact-phone-multi-selection-dropdown.vue';
import ContactPhoneRow from '@/components/TablesRows/contact-phone-row.vue';
import VInput from '@/components/Inputs/IconInput.vue';
import QueuesDropdown from '@/components/Dropdown/queues-multi-selection-dropdown.vue';
import FinishActivityModal from './Callback.FinishActivityModal';
import CancelActivityModal from './Callback.CancelActivityModal';
import callbackMixin from '@/mixins/callbacks.js';
import { getCallbacks, countCallbacks } from '@/services/cblist';
import { debounce } from '@/helpers';

export default {
  mixins: [callbackMixin],
  components: {
    AbandonActivities,
    Observer,
    LoadingRing,
    DatePicker,
    VButtonSecondary,
    VButtonPrimary: VButton,
    Modal,
    ModalBody,
    ContactsDropdown,
    ContactPhoneRow,
    VInput,
    QueuesDropdown,
    FinishActivityModal,
    CancelActivityModal,
  },
  data() {
    return {
      isFinishActivityModalOpen: false,
      isCancelActivityModalOpen: false,
      activity: {},

      isFiltersModalOpen: false,
      hasMoreCallbacks: true,
      loadingMoreCallbacks: false,
      callbacks: [],
      searchAfter: null,
      loading: false,

      createdAtGte: null,
      createdAtLte: null,

      searchContactNumber: '',

      contactsIds: [],

      queues: [],

      advancedFilters: {
        contacts: {},
        queues: [],
      },
    };
  },
  methods: {
    async handleFinishCallback(data) {
      await this.finishCallBack(data);
      this.isFinishActivityModalOpen = false;
      await this.fetchCallbacks();
    },
    finishActivity(data) {
      this.activity = data;
      this.isFinishActivityModalOpen = true;
    },
    async handleCancelCallback(data) {
      await this.removeCallBack(data);
      this.isCancelActivityModalOpen = false;
      await this.fetchCallbacks();
    },
    cancelActivity(data) {
      this.activity = data;
      this.isCancelActivityModalOpen = true;
    },
    handleSelectedQueues(data) {
      this.advancedFilters.queues = data;
    },
    handleSelectedContacts(data) {
      this.advancedFilters.contacts = data;
    },
    async applyAdvancedFilters() {
      this.contactsIds = Object.keys(this.advancedFilters.contacts);
      this.queues = this.advancedFilters.queues;

      this.isFiltersModalOpen = false;

      await this.fetchCallbacks();
    },
    async resetAdvancedFilters() {
      this.advancedFilters.contacts = {};
      this.contactsIds = [];

      this.advancedFilters.queues = [];
      this.queues = [];

      this.isFiltersModalOpen = false;
      await this.fetchCallbacks();
    },
    filterByContactNumber: debounce(async function () {
      await this.fetchCallbacks();
    }, 500),
    async handleDateFilter(date) {
      this.createdAtGte = date.start;
      this.createdAtLte = date.end;

      await this.fetchCallbacks();
    },
    async resetDateFilter() {
      this.createdAtGte = null;
      this.createdAtLte = null;

      await this.fetchCallbacks();
    },
    openOverlay(data) {
      this.$emit('open-overlay', data);
    },
    calling(data) {
      this.$emit('calling', data);
    },
    async fetchCallbacks() {
      if (!this.user.queues) return;

      this.loading = true;

      try {
        const payload = {
          categories: ['abandon'],
          notAssigned: true,
          createdAtGte: this.createdAtGte,
          createdAtLte: this.createdAtLte,
          contactsIds: this.contactsIds,
        };

        if (this.searchContactNumber?.length) {
          payload.contactNumber = this.searchContactNumber;
        }

        if (this.queues?.length) {
          payload.queues = this.queues;
        } else {
          payload.queues = Object.keys(this.user.queues);
        }

        const response = await getCallbacks(payload);
        const count = await countCallbacks(payload);

        this.hasMoreCallbacks = Boolean(response.nextCursor);
        this.searchAfter = response.nextCursor;
        this.callbacks = response.data ?? [];

        this.$emit('update-count', count);

        const abandonCategoryId = response.data[0]?.Category.id;
        this.$store.commit('SET_ABANDONID', abandonCategoryId);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreCallbacks() {
      if (!this.hasMoreCallbacks || this.loadingMoreCallbacks) return;

      this.loadingMoreCallbacks = true;

      try {
        const payload = {
          categories: ['abandon'],
          notAssigned: true,
          searchAfter: this.searchAfter,
          createdAtGte: this.createdAtGte,
          createdAtLte: this.createdAtLte,
          contactsIds: this.contactsIds,
        };

        if (this.searchContactNumber?.length) {
          payload.contactNumber = this.searchContactNumber;
        }

        if (this.queues?.length) {
          payload.queues = this.queues;
        } else {
          payload.queues = Object.keys(this.user.queues);
        }

        const response = await getCallbacks(payload);

        this.hasMoreCallbacks = Boolean(response.nextCursor);
        this.searchAfter = response.nextCursor;
        this.callbacks = [...this.callbacks, ...response.data];
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingMoreCallbacks = false;
      }
    },
  },
  async created() {
    await this.fetchCallbacks();
  },
};
</script>
