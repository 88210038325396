<template lang="pug">
.w-full.relative
  .flex.flex-col.mb-2.items-start
    p.font-semibold.text-gray-500.font-inter(v-if="label") {{ label }}
    p.text-xs.text-gray-500(v-if="description") {{ description }}
  v-dropdown.w-full(placement="bottom", autoSize="min")
    .w-full.border.border-gray-200.rounded-md.flex.justify-between.py-3.font-inter.cursor-pointer(
      class="bg-[#F8F9FA]"
    )
      p.ml-5.text-base {{ selectedProviders.length ? `(${selectedProviders.length}) Provedor(es) selecionado(s)` : 'Selecione um ou mais provedores' }}
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    template(#popper)
      .p-2
        slot(
          name="search"
          :search="handleSearchProvider"
        )
        .flex.flex-col.max-h-60.overflow-y-scroll.mt-2.items-start.justify-start
          div.w-full(
            v-for="provider in providers.filter((p) => p.toLowerCase().includes(search.toLowerCase()))",
            :key="provider",
            @click="select(provider)"
          )
            slot(
              name="items",
              :provider="provider",
              :selected="selectedProviders.includes(provider)"
            )
          .flex.p-2(v-if="loading")
            loading-ring

          p.font-plus.text-sm.text-center.py-2.text-gray-500(v-if="!providers.length") Nenhum provedor encontrado
        .flex.w-full.justify-center.gap-2.pt-2
          slot(
            name="options"
            :selectAll="selectAll"
            :deselectAll="deselectAll"
          )
</template>

<script>
import { hideAllPoppers, Dropdown } from 'floating-vue';
import LoadingRing from '../LoadingRing.vue';
import Observer from '../Observer.vue';

export default {
  props: {
    description: { type: String, default: '' },
    label: { type: String, default: '' },
    preSelectedProviders: { type: Array, default: () => [] },
  },
  components: {
    vDropdown: Dropdown,
    LoadingRing,
    Observer,
  },
  data() {
    return {
      search: '',
      loading: false,
      providers: [],
      selectedProviders: [],
    };
  },
  watch: {
    selectedProviders(val) {
      return this.$emit('selected-providers-list', val);
    },
  },
  computed: {
    chatProviders() {
      return process.env.VUE_APP_CHAT_PROVIDERS;
    },
  },
  methods: {
    select(provider) {
      if (this.selectedProviders.includes(provider)) {
        this.selectedProviders = this.selectedProviders.filter((p) => p !== provider);
      } else {
        this.selectedProviders = [...this.selectedProviders, provider];
      }
    },
    selectAll() {
      this.selectedProviders = this.providers.filter((p) => p.toLowerCase().includes(this.search.toLowerCase()));
    },
    deselectAll() {
      this.selectedProviders = [];
    },
    handleSearchProvider(input) {
      this.search = input;
    },
  },
  created() {
    this.providers = this.chatProviders.split(',');
    this.selectedProviders = this.preSelectedProviders;
  },
};
</script>
