<template lang="pug">
.activity-info-box
  .activity-img-info-aligner
    .callback-callto-user-img.activity-info-size(v-if='failedImg')
      | {{ data.contact.name[0].toUpperCase() }}
    img.callback-callto-user-img.activity-info-size(
      v-else-if='!failedImg',
      :src='`${this.requestLinks.clist}/uploads/${data.contact.avatar}.jpg`',
      @error='failedImg = true'
    )
    .activity-info-texts
      p.activity-info-name {{ data.contact.name || data.contact.username }}
      p.activity-info-org(v-if='data.contact.organization') {{ data.contact.organization.name }}
      p.activity-info-org(v-else) {{ _formatNumber(data.payload.contactNumber) }}
    .callback-callto-tag-margin.activity-user-tag(
      v-if='data.contact?.category'
    ) {{ data.contact.category }}
    .client-text.Colega.colega-tag.p-1(v-else-if='data.contact.access_token') Colega
    .unknown-text.no-tag-margin(v-else) Sem categoria
    .activity-phones-aligner
      a.call-duration-text(style='color: #495057; white-space: nowrap; margin-right: 20px') {{ _formatNumber(data.payload.contactNumber) }}
      .call-duration-text(v-if='data.contact.emails' style='color: #495057')
        | {{ data.contact.emails[0].address }}
    .flex-end.callback-actions-icon-aligner
      span.flex.items-center.justify-center.material-icons.notranslate.callback-action-icon(
        v-tooltip="'Cancelar atividade'"
        @click.stop="$root.$emit('action', { actualModal: 'CancelActivity', actualActivity: data })"
      ) &#xE5CD;
      span.flex.items-center.justify-center.material-icons.notranslate.callback-action-icon(
        v-tooltip="'Marcar como concluída'"
        @click.stop="$root.$emit('action', {actualModal: 'FinishActivity', actualActivity: data })"
      ) &#xE5CA;
      span.flex.items-center.justify-center.material-icons.notranslate.callback-call-icon(
        v-tooltip="'Ligar para ' + data?.contact?.name || 'Contato desconhecido'"
        @click.stop='makeCall(data.payload.contactNumber)'
      ) phone
  .activity-info-separator
  .activity-downside-aligner
    .activity-note-area
      p.title Observações
      p.info(v-if='data.payload.note') {{ data.payload.note }}
      p.info(
        v-else-if="data.payload.categoryId && data.payload.categoryId == this.$store.getters['GET_ABANDONID']"
      )
        span(v-if="isChat") Expirado em {{ data.payload.description }}
        span(v-else)
          | Abandono em fila {{ data.payload.description }} após
          | {{ abandonTime(data.payload.date) }}
      p.info(v-else) Sem observações
    .activity-assigner-area(v-if='data.assignedBy.username')
      p.title Criado por
      p.info {{ data.assignedBy.username }}
    .activity-assigner-area(v-else)
      p.title(v-if="isChat") Expirado em
      p.title(v-else) Abandono em
      .callback-origin-tag Fila {{ data.payload.description }}
    .activity-add-area
      p.title Adicionado
      p.info {{ timeAdded(data.payload.createdAt) }}
</template>

<script>
import callbackMixin from '@/mixins/callbacks.js';

export default {
  mixins: [callbackMixin],
  props: ['data'],
  data() {
    return {
      failedImg: false,
    };
  },
  computed: {
    payload() {
      return this.data;
    },
    isChat() {
      return this.data.payload.type === 'Chat';
    },
  },
  methods: {
    timeAdded(date) {
      let dateNow = new Date();
      let addedTime = (dateNow - new Date(date)) / 1000;
      if (addedTime > 59 && addedTime < 3600) {
        return 'Há ' + parseInt(addedTime / 60) + ' min';
      } else if (addedTime < 60) {
        return 'Há ' + parseInt(addedTime) + ' seg';
      } else if (addedTime > 3599 && addedTime < 3600 * 24) {
        if (parseInt(addedTime / 3600) === 1) {
          return 'Há 1 hora';
        }
        return 'Há ' + parseInt(addedTime / 3600) + ' horas';
      } else if (addedTime > 3600 * 24) {
        return 'Há ' + parseInt(addedTime / 3600 / 24) + ' dias';
      }
    },
    abandonTime(holdtime) {
      if (holdtime > 59 && holdtime < 3600) {
        return parseInt(holdtime / 60) + ' minutos';
      } else if (holdtime < 60) {
        return parseInt(holdtime) + ' segundos';
      } else if (holdtime > 3599 && holdtime < 3600 * 24) {
        if (parseInt(holdtime / 3600) === 1) {
          return '1 hora';
        }
        return parseInt(holdtime / 3600) + ' horas';
      } else if (holdtime > 3600 * 24) {
        return parseInt(holdtime / 3600 / 24) + ' dias';
      }
    },
    makeCall(num) {
      if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: num });
      else this.callNumber(num);
    },
  },
};
</script>

<style lang="postcss" scoped>
.material-icons {
  color: #fafafa;
}
</style>

<style>
.activity-downside-aligner {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  height: fit-content;
}
.activity-assigner-area {
  margin-right: 40px;
  white-space: nowrap;
}
.activity-note-area {
  margin-right: 40px;
  width: fit-content;
  max-width: 483px;
}
.activity-downside-aligner .title {
  font-family: 'Inter', sans-serif;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}
.activity-downside-aligner .info {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}
.flex-end {
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  position: absolute;
}
.activity-info-separator {
  height: 1px;
  background: #e9ecef;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}
.activity-phones-aligner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 90px;
  margin-top: 8px;
  position: absolute;
}
.activity-user-tag {
  padding: 5px 12px;
  height: max-content;
  width: max-content;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.1);
  border-radius: 25px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}
.activity-img-info-aligner {
  display: flex;
  position: relative;
  flex-direction: row;
}
.activity-info-box {
  width: 100%;
  height: fit-content;
  min-height: fit-content;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 24px;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
}
.activity-info-size {
  width: 64px !important;
  height: 64px !important;
  margin-right: 20px;
}
.activity-info-org {
  font-family: 'Inter', sans-serif;
  margin-top: 8px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}
.activity-info-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.info {
  word-break: break-all;
}
</style>
