import { createAgent, makeCall } from '../services/sip';
import { SessionState } from 'sip.js';
import csService from '@/services/cservice.js';
const bell = new Audio(require('@/assets/sounds/end-call-audio.mp3'));

export default {
  namespaced: true,
  state: {
    userAgent: null,
    registerer: null,
    session: null,
    sessionOptions: null,
    lastMessageTimestamp: null,
    muted: false,
    transferring: false,
    endpoint: '',
    transport: '',
    sessionState: '',
    isCallLoading: false,
    isDragging: false,
    isResizing: false,
    activeSider: false,
    offset: { x: 0, y: 0 },
    position: { x: -271, y: 46 },
    size: { width: 314, height: 531 },
    matesList: [],
    loadingMates: false,
  },
  getters: {
    activeSider: function (state) {
      return state.activeSider;
    },
    size: function (state) {
      return state.size;
    },
    getMateStatus: function (state) {
      return (endpoint) => {
        const mate = state.matesList.find((mate) => mate.endpoint === endpoint);
        return mate.status;
      };
    },
    loadingMates: function (state) {
      return state.loadingMates;
    },
    matesList: function (state) {
      return state.matesList;
    },
    isDragging: function (state) {
      return state.isDragging;
    },
    isResizing: function (state) {
      return state.isResizing;
    },
    offset: function (state) {
      return state.offset;
    },
    position: function (state) {
      return state.position;
    },
    transferring: function (state) {
      return state.transferring;
    },
    muted: function (state) {
      return state.muted;
    },
    configuration: function (state) {
      return {
        endpoint: state.endpoint,
        transport: state.transport,
      };
    },
    agent: function (state) {
      return state.userAgent;
    },
    session: function (state) {
      return {
        session: state.session,
        options: state.sessionOptions,
      };
    },
    sessionState(state) {
      return state.sessionState;
    },
    webphoneStatus: function (state) {
      if (!state.userAgent) return {};
      return {
        agentConnected: state.userAgent.isConnected(),
        agentState: state.userAgent.state,
        transportState: state.userAgent.transport.state,
        transportConnected: state.userAgent.transport.isConnected(),
        transportProtocol: state.userAgent.transport.protocol,
        registerState: state.registerer.state,
      };
    },
    lastMessageTimestamp(state) {
      return state.lastMessageTimestamp;
    },
    isCallLoading(state) {
      return state.isCallLoading;
    },
  },
  mutations: {
    setActiveSider(state, value) {
      state.activeSider = value;
    },
    setIsResizing(state, value) {
      state.isResizing = value;
    },
    setSize(state, value) {
      state.size = value;
    },
    setLoadingMates(state, value) {
      state.loadingMates = value;
    },
    updateMateStatus(state, { endpoint, status }) {
      const mate = state.matesList.find((mate) => mate.endpoint === endpoint);
      if (mate) mate.status = status;
    },
    setMatesList(state, value) {
      state.matesList = value;
    },
    setIsDragging(state, value) {
      state.isDragging = value;
    },
    setOffset(state, value) {
      state.offset = value;
    },
    setPosition(state, value) {
      state.position = value;
    },
    setTransferring(state, value) {
      state.transferring = value;
    },
    setMuted(state, value) {
      state.muted = value;
    },
    setConfiguration(state, { endpoint, transport }) {
      if (endpoint) state.endpoint = endpoint;
      if (transport) state.transport = transport;
    },
    setLastMessageTimestamp(state, timestamp) {
      state.lastMessageTimestamp = timestamp;
    },
    setSession(state, { session, sessionOptions }) {
      state.session = session;
      state.sessionOptions = sessionOptions;
    },
    setUserAgent(state, { userAgent, registerer }) {
      state.userAgent = userAgent;
      state.registerer = registerer;
    },
    setSessionState(state, sessionState) {
      state.sessionState = sessionState;
    },
    setCallLoading(state, isLoading) {
      state.isCallLoading = isLoading;
    },
    destroyUserAgent(state) {
      state.userAgent.stop();
      state.registerer.dispose();
      state.registerer = null;
      state.userAgent = null;
      state.session = null;
      state.sessionOptions = null;
    },
  },
  actions: {
    async createUserAgent({ commit, getters, rootGetters }, { endpoint, username }) {
      try {
        const { server, secret, transport } = await csService.getDeviceConfig(endpoint).catch((e) => {});
        commit('setConfiguration', { endpoint, transport });
        if (transport !== 'transport-wss') return;
        const { userAgent, registerer } = createAgent({
          server,
          password: secret,
          endpoint,
          username,
        });
        userAgent.delegate = {
          onInvite(invitation) {
            // An Invitation is a Session
            const session = invitation;
            // Setup incoming session delegate
            session.delegate = {
              // Handle incoming REFER request.
              onRefer(referral) {
                // ...
              },
            };
            const currentSession = getters.session;
            if (currentSession.session != null && currentSession.session.state !== 'Terminated') {
              session.session.reject();
              return;
            }
            // Handle incoming session state changes.
            session.stateChange.addListener((newState) => {
              switch (newState) {
                case SessionState.Establishing:
                  {
                    // Session is establishing.
                    commit('setSessionState', SessionState.Establishing);
                  }
                  break;
                case SessionState.Established: {
                  // Session has been established.
                  commit('setSessionState', SessionState.Established);
                  commit(
                    'cstate/SET_DATA',
                    {
                      payload: {
                        from: session.remoteIdentity.uri.user,
                        to: rootGetters['user/GET_USER'].endpoint,
                      },
                      dialerState: 4,
                    },
                    { root: true },
                  );
                  break;
                }
                case SessionState.Terminated: {
                  commit('cstate/DESTROY', {}, { root: true });
                  commit('setSessionState', SessionState.Terminated);

                  const oldTitle = document.title;
                  bell.volume = rootGetters['userPreferences/getAudioVolume'];
                  bell.play();
                  if (document.hidden) {
                    const blinkTitleInterval = setInterval(() => {
                      document.title = document.title == 'Atenção' ? 'Chamada encerrada' : 'Atenção';
                    }, 1000);
                    document.addEventListener('visibilitychange', () => {
                      if (!document.hidden) {
                        document.title = oldTitle;
                        return clearInterval(blinkTitleInterval);
                      }
                    });
                  }

                  break;
                }
                default:
                  break;
              }
            });

            // Handle incoming INVITE request.
            let constrainsDefault = {
              audio: true,
              video: false,
            };

            const options = {
              sessionDescriptionHandlerOptions: {
                constraints: constrainsDefault,
              },
            };
            notifyMe();
            commit('setSession', {
              session,
              sessionOptions: options,
            });
            commit(
              'cstate/SET_DATA',
              {
                payload: {
                  from: session.remoteIdentity.uri.user,
                },
                dialerState: 1,
              },
              {
                root: true,
              },
            );
          },
          onConnect: () => {
            registerer.register();
          },
          onRegister() {},
        };
        userAgent
          .start()
          .then(() => {})
          .catch((e) => {
            console.error(e);
          });
        commit('setUserAgent', {
          userAgent,
          registerer,
        });
      } catch (error) {}
    },
    makeCall({ getters, commit, rootGetters }, { callNum, toId }) {
      const loading = getters.isCallLoading;
      if (loading) return;
      commit('setCallLoading', true);
      const userAgent = getters.agent;
      const { outgoingSession, outgoingSessionOptions } = makeCall({
        userAgent,
        callNum,
      });
      // Handle outgoing session state changes.
      outgoingSession.delegate = {
        onBye(bye) {},
        onSessionDescriptionHandler(sdh, provisional) {},
      };
      outgoingSession.stateChange.addListener((newState) => {
        switch (newState) {
          case SessionState.Establishing:
            {
              // Session is establishing.
              commit('setCallLoading', false);
              commit('setSessionState', SessionState.Establishing);
              commit(
                'cstate/SET_DATA',
                {
                  payload: { to: outgoingSession.remoteIdentity.uri.user, toId },
                  dialerState: 2,
                },
                { root: true },
              );
            }
            break;
          case SessionState.Established:
            {
              // Session has been established.
              commit('setSessionState', SessionState.Established);
              commit(
                'cstate/SET_ONCALL_DATA',
                {
                  toId,
                  to: outgoingSession.remoteIdentity.uri.user,
                  from: outgoingSession.request.fromURI.user,
                },
                { root: true },
              );
            }
            break;
          case SessionState.Terminated:
            {
              // Session has terminated.
              commit('setSessionState', SessionState.Terminated);
              commit('cstate/DESTROY', {}, { root: true });
              commit('setSession', {
                session: null,
                sessionOptions: null,
              });

              const oldTitle = document.title;
              bell.volume = rootGetters['userPreferences/getAudioVolume'];
              bell.play();
              if (document.hidden) {
                const blinkTitleInterval = setInterval(() => {
                  document.title = document.title == 'Atenção' ? 'Chamada encerrada' : 'Atenção';
                }, 1000);
                document.addEventListener('visibilitychange', () => {
                  if (!document.hidden) {
                    document.title = oldTitle;
                    return clearInterval(blinkTitleInterval);
                  }
                });
              }
            }
            break;
        }
      });
      commit('setSession', {
        session: outgoingSession,
        sessionOptions: outgoingSessionOptions,
      });
    },
  },
};

function notifyMe() {
  if (!document.hidden && document.hasFocus()) return;
  if (Notification.permission !== 'granted') Notification.requestPermission();
  const notification = new Notification('Nova ligação!', {
    icon: require('@/assets/images/opens-app-logo-for-notification.png'),
    body: 'Alguém esta tentando falar com você!',
  });
  notification.onclick = function (e) {
    parent.focus();
    //window.focus(); //just in case, older browsers
    e.target.close();
  };
}
