<template lang="pug">
.flex.justify-between.w-full.items-center.cursor-move(:class="{ 'cursor-move': isDragging }", @mousedown="startDrag")
  .flex.gap-2.items-center.justify-center
    p.font-plus.text-white
      slot
    .w-1.h-1.rounded-full.bg-gray-500
    p.font-plus.text-gray-500.text-xs SIP {{ this.user.endpoint }}
  .flex.gap-1
    f-tooltip
      .material-icons.notranslate.text-lg.cursor-pointer.transition-all(
        class="hover:opacity-50"
      ) info
      template(#popper)
        device-status-info
    .material-icons.notranslate.text-lg.cursor-move.transition-all(
      class="hover:opacity-50"
      v-tooltip="'Mover dialer'"
    ) drag_handle
    .material-icons.notranslate.text-lg.cursor-pointer.transition-all(
      class="hover:opacity-50"
      v-tooltip="'Fechar dialer'"
      @click="handleClose"
    ) remove
</template>
<script>
import DeviceStatusInfo from '@/components/Dialer/dialer-device-status-info.vue';
import { Tooltip } from 'floating-vue';

export default {
  components: {
    FTooltip: Tooltip,
    DeviceStatusInfo,
  },
  computed: {
    isDragging() {
      return this.$store.getters['sip/isDragging'];
    },
    offset() {
      return this.$store.getters['sip/offset'];
    },
    position() {
      return this.$store.getters['sip/position'];
    },
  },
  methods: {
    handleClose() {
      this.$store.commit('dialer/setIsDialerOpen', false);
    },
    startDrag(event) {
      this.$store.commit('sip/setIsDragging', true);
      this.$store.commit('sip/setOffset', {
        x: event.clientX - this.position.x,
        y: event.clientY - this.position.y,
      });
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        this.$store.commit('sip/setPosition', {
          x: event.clientX - this.offset.x,
          y: event.clientY - this.offset.y,
        });
      }
    },
    stopDrag() {
      this.$store.commit('sip/setIsDragging', false);
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
  },
  onUnmounted() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.stopDrag);
  },
};
</script>
