<template lang="pug">
portal(to="overlay-outlet")
  transition(name="fade", duration="200")
    .fixed.inset-0.bg-black.bg-opacity-50.flex.items-center.justify-start.z-40(
      class="dark:bg-opacity-90"
      @click.self="$emit('click')"
    )
      .bg-white.h-screen.shadow-xl.flex.flex-col.bg-white.rounded-r-lg(
        class="w-[min(95%,95rem)]"
      )
        slot(name="header")
        slot(name="content")
</template>

<script>
export default {
  name: 'Overlay',
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
