<template lang="pug">
dialer-layout
  template(#header)
    dialer-header Chamando
  template(#body)
    .flex.flex-col.gap-8.items-center.justify-center.w-full(class='h-[25rem]')
      .font-plus.text-white Chamando...

      .flex.flex-col.gap-5
        .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.overflow-hidden
          avatar(
            v-if="!identityContact.unknown"
            :src="identityContact.avatarUrl",
            :avatarId="identityContact.id",
          )
          img.h-full(
            v-else
            src="@/assets/images/default_pfp.png"
          )

        .flex.flex-col.gap-0.items-center
          .text-white.font-semibold.font-plus {{ identityContact?.name }}
          .text-sm.text-slate-500.font-plus {{ toNumber }}
          .text-sm.text-slate-500.font-plus(v-if="!identityContact?.unknown") {{  identityContact?.organization || "Sem organização" }}

        .flex.gap-2(v-if="loading")
          loading-ring

        dialer-option(
          red,
          icon="call_end",
          @click="hangUpCall",
          v-if="isWebRTC && !loading"
        )
</template>

<script>
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import DialerHeader from '@/components/Dialer/dialer-header.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import { getUserRingingSound } from '@/services/cservice';
import LoadingRing from '@/components/LoadingRing.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import SipMixin from '@/mixins/invite-calls.js';

export default {
  mixins: [UserOrContactMixin, SipMixin],
  components: {
    DialerOption,
    DialerLayout,
    DialerHeader,
    LoadingRing,
    Avatar,
  },
  data() {
    return {
      failedImg: false,
      loading: false,
      translateCServiceStatus: {
        enabled: 'Disponível',
        disabled: 'NaoPerturbe',
        online: 'Disponível',
        auto: 'Disponível',
      },
    };
  },
  computed: {
    callData() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    toNumber() {
      return this.data?.to || this.callData?.to;
    },
    toId() {
      return this.data?.toId || this.callData?.toId;
    },
  },

  watch: {
    callData(value) {
      if (value) {
        this.setContactOrOperator({
          id: this.toId,
          number: this.toNumber,
        });
      }
    },
  },
  methods: {
    async hangUpCall() {
      if (this.loading) return;
      this.loading = true;
      try {
        await this._hangUpCall();
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          window.location.pathname = '/auth/login';
        }
      } finally {
        this.loading = false;
        this.$emit('hangup-call');
      }
    },
    playAudio() {
      if (!this.isWebRTC) return;
      const volume = this.$store.getters['userPreferences/getAudioVolume'];
      const audio = this.$store.getters['cstate/ringingSound'];
      audio.volume = volume;
      audio.play();
    },
  },
  mounted() {
    try {
      const localStorageUserRingingSound = localStorage.getItem(`@yunique:ringing-sound:${this.user.id}`);
      if (localStorageUserRingingSound) {
        const ringingSounds = this.$store.getters['cstate/ringingSounds'];
        const selectedRingingSound = ringingSounds[localStorageUserRingingSound] || ringingSounds['default'];

        this.$store.commit('cstate/setRingingSound', selectedRingingSound);
      } else {
        getUserRingingSound(this.user.id).then((data) => {
          if (data.ringing_sound) {
            const ringingSounds = this.$store.getters['cstate/ringingSounds'];
            const selectedRingingSound = ringingSounds[data.ringing_sound] || ringingSounds['default'];

            this.$store.commit('cstate/setRingingSound', selectedRingingSound);
            localStorage.setItem(`@yunique:ringing-sound:${this.user.id}`, data.ringing_sound);
          }
        });
      }
    } catch (e) {}
    this.playAudio();
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.addEventListener('ended', this.playAudio);
  },
  beforeDestroy() {
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.removeEventListener('ended', this.playAudio);
    audio.pause();
    audio.currentTime = 0;
  },
  destroyed() {
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.removeEventListener('ended', this.playAudio);
    audio.pause();
    audio.currentTime = 0;
  },
  async created() {
    await this.setContactOrOperator({
      id: this.toId,
      number: this.toNumber,
    });
  },
};
</script>

<style>
@keyframes pulse-red {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(var(--red-default), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(220, 96, 88, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(220, 96, 88, 0);
  }
}
.turnoff-call-icon {
  cursor: pointer;
  font-size: 34px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #fff;
  background: var(--red-default);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-red 2s ease infinite;
}
.send-csat-icon {
  cursor: pointer;
  font-size: 34px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: var(--red-default);
  background: #212529;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-red 2s ease infinite;
}

.call-received-note {
  margin: 5px;
  font-size: 0.75rem;
  color: rgb(184, 184, 184);
}
</style>
