<template lang="pug">
.rounded-md.overflow-hidden.flex.bg-slate-900.items-start.relative.h-full.w-full(class="dark:invert")
  .flex.divide-y.flex-col.divide-slate-800.w-full.h-full(:class='"border-r border-slate-800"')
    .flex.p-2.w-full.h-8(
      v-if="$slots.header"
    )
      slot(name="header")
    .flex.px-2.py-4.h-full.justify-start.w-full(
      v-if="$slots.body"
    )
      slot(name="body")
    .flex.p-2.w-full.h-8(
      v-if="$slots.footer"
    )
      slot(name="footer")
  .h-full(v-if="$slots.sider", class="min-w-[19rem]")
    slot(name='sider')
  .material-icons.text-white.cursor-se-resize.absolute.-bottom-1.-right-1.text-xs.-rotate-45(
    class="hover:opacity-50"
    @mousedown="startResize"
  ) maximize
</template>
<script>
export default {
  computed: {
    isResizing() {
      return this.$store.getters['sip/isResizing'];
    },
    size() {
      return this.$store.getters['sip/size'];
    },
  },
  methods: {
    handleClose() {
      this.$store.commit('dialer/setIsDialerOpen', false);
    },
    startResize(event) {
      event.preventDefault();
      this.$store.commit('sip/setIsResizing', true);

      const initialWidth = this.size.width;
      const initialHeight = this.size.height;
      const initialX = event.clientX;
      const initialY = event.clientY;

      const onResize = (e) => {
        if (this.isResizing) {
          const minWidth = this.$slots.sider ? 583 : 314;
          const newWidth = Math.max(minWidth, initialWidth + (e.clientX - initialX));
          const newHeight = Math.max(531, initialHeight + (e.clientY - initialY));

          this.$store.commit('sip/setSize', {
            width: newWidth,
            height: newHeight,
          });
        }
      };

      const stopResize = () => {
        this.$store.commit('sip/setIsResizing', false);
        document.removeEventListener('mousemove', onResize);
        document.removeEventListener('mouseup', stopResize);
      };

      document.addEventListener('mousemove', onResize);
      document.addEventListener('mouseup', stopResize);
    },
  },
  beforeUnmount() {
    if (this.isResizing) {
      this.$store.commit('sip/setIsResizing', false);
    }
  },
};
</script>
