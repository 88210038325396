<template lang="pug">
.horizontal-aligner.flex.h-full
  .profile-aligner.flex.flex-col.items-center.justify-start.gap-2.mt-2(style="width: 55%")
    .profile-box.flex.justify-center.w-48.h-48.bg-brand.bg-opacity-20.rounded-md.cursor-pointer.transition(
      class="hover:bg-opacity-30 active:scale-95 dark:invert",
      @click="showUploadImageModal = true"
    )
      span.material-icons.notranslate(
        v-if="!uploaded",
        class="dark:invert",
        style="color: var(--brand-color); font-size: 80px !important"
      ) perm_identity
      img(
        ref="imgBox",
        style="border-radius: 4px; width: 168px; height: 168px; display: none"
      )
    v-button-secondary(
      role="button"
      @click="showUploadImageModal = true"
    ) Adicionar ou alterar foto
    upload-image(v-if="showUploadImageModal", title="Alterar foto do contato", @close="showUploadImageModal = false", @apply-image="handleApplyImage")

  .input-aligner(style="width: 45%")
    .flex
      simple-input.w-full(v-model="contact.name" label="Nome")
      f-tooltip(placement='top' v-if="hasAgile")
        .material-icons.notranslate.p-2.ml-2.animate-ping.text-brand-800.absolute.cursor-pointer(
          v-if="isOverlay && agileEvent && openedByContextValue"
          @click="openContextModal('name')"
          class="transition-all ease-linear hover:text-brand-300"
        ) bolt
        .material-icons.notranslate.p-2.ml-2.cursor-pointer(
          v-if="isOverlay && agileEvent"
          @click="openContextModal('name')"
          class="transition-all ease-linear hover:text-brand-300"
        ) bolt
        template(#popper)
          p Utilizar dados do contexto
    v-dropdown.mt-4(
      placement="bottom-start",
      :triggers="[]",
      :shown="showOrgList",
      :autoHide="false"
      auto-size
    )
      simple-input(
        v-model="selectedOrgName"
        label="Organização"
        @focus="watchOrganization",
        @blur="showOrgList = false"
        :key="orgKey"
        :class="{'!border-red-400': orgError}"
        )
      template(#popper)
        .max-h-72.overflow-auto.divide-y
          li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
            v-for="org in organizationList.filter((o) => { return o.name.toLowerCase().startsWith(selectedOrgName.toLowerCase()); })",
            :key="org.id"
          )
            p.ml-4.text-gray-400.cursor-pointer.w-full(
              @mousedown="selectOrganization(org)"
            ) {{ org.name }}
          li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
            v-if="organizationList.filter((o) => { return o.name.toLowerCase().startsWith(selectedOrgName.toLowerCase()); }).length < 1"
          )
            p.ml-4.text-brand-400.cursor-pointer(
              @mousedown="addNewOrganization"
            )
              span.font-semibold(class="dark:invert") Adicionar nova organização
    .flex.mt-4
      simple-input.w-full(
        label="CPF",
        v-model="contact.cpf",
        type="text",
        :caract="14"
      )
      f-tooltip(placement='top' v-if="hasAgile")
        .material-icons.notranslate.p-2.ml-2.animate-ping.text-brand-800.absolute.cursor-pointer(
          v-if="isOverlay && agileEvent && openedByContextValue"
          @click="openContextModal('cpf')"
          class="transition-all ease-linear hover:text-brand-300"
        ) bolt
        .material-icons.notranslate.p-2.ml-2.cursor-pointer(
          v-if="isOverlay && agileEvent"
          @click="openContextModal('cpf')"
          class="transition-all ease-linear hover:text-brand-300"
        ) bolt
        template(#popper)
          p Utilizar dados do contexto
    .flex.mt-4.flex-col.gap-2
      .flex.w-full.gap-2
        simple-input.w-full(
          v-for="(email, index) in contact.emails"
          v-model="email.address"
          label="E-mail"
          :key="email.id"
        )
        f-tooltip(placement='top' v-if="hasAgile")
          .material-icons.notranslate.p-2.ml-2.animate-ping.text-brand-800.absolute.cursor-pointer(
            v-if="isOverlay && agileEvent && openedByContextValue"
            @click="openContextModal('email')"
            class="transition-all ease-linear hover:text-brand-300"
          ) bolt
          .material-icons.notranslate.p-2.ml-2.cursor-pointer(
            v-if="isOverlay && agileEvent"
            @click="openContextModal('email')"
            class="transition-all ease-linear hover:text-brand-300"
          ) bolt
          template(#popper)
            p Utilizar dados do contexto
      .flex.w-full.justify-end
        a.font-bold.font-inter.text-xs.text-center.text-brand.tracking-widest.w-auto(
          class="hover:text-opacity-60"
          role="button",
          v-on:click="appendEmailInput()",
        ) Adicionar email
    v-dropdown.mt-4(
      placement="bottom-start",
      :triggers="[]",
      :shown="showTagList",
      :autoHide="false"
      auto-size
    )
      simple-input(
        v-model="selectedCategoryName"
        label="Categoria"
        @focus="showTagList = true",
        @blur="showTagList = false"
        :key="contact.categoryId"
        )
      template(#popper)
        .max-h-72.overflow-auto.divide-y
          li.flex.justify-start.items-center.h-10.bg-zinc-50.cursor-pointer(
            v-for="category in computedCategories",
            :key="category.id"
          )
            p.ml-4.text-gray-400.w-full(
              @mousedown="selectCategory(category)"
            ) {{ category.name }}

          li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
            v-if="!computedCategories.length"
          )
            p.ml-4.text-brand-400.cursor-pointer(
              @mousedown="displayNewCategoryModal=true"
            )
              span.font-semibold(class="dark:invert") Adicionar nova categoria

    modal(v-if="displayNewCategoryModal" @click='displayNewCategoryModal=false')
      modal-body
        template(v-slot:header)
          v-heading Criar categoria

        template(v-slot:body)
          p.text-sm.text-zinc-400.mb-4 Preencha os campos a baixo para criar uma categoria
          simple-input.w-96(label='Nova categoria' v-model="newCategoryName")

        template(v-slot:footer)
          .flex.justify-center.items-center
            v-button.mr-2(@click='addNewCategory') Criar
            v-button-secondary(@click='displayNewCategoryModal=false') cancelar

    #number-div
      label.up-placeholder Telefone principal
      .flex.gap-4.w-full
        v-dropdown.w-14.h-full(placement="bottom")
          button(class="flex items-center flex-1 px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none group h-11 hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10")
            .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") +{{ contact.phones[0].ddi }}
            span(class="text-gray-400 material-icons notranslate group-hover:text-brand") keyboard_arrow_down
          template(#popper)
            ul(class="flex flex-col justify-start w-full gap-2 p-1 divide-y max-h-40")
              .flex.h-10.w-full.mt-1
                icon-input(:label="'DDI'" v-model="ddiSearch")
              dropdown-item(
                v-for="(ddi, index) in avaliableDdis.filter((ddi) => ddi.number.includes(ddiSearch))",
                @click="contact.phones[0].ddi = ddi.number",
                :label="`${ddi.flag} +${ddi.number}`",
                :key="index",
                :hide-icon="true"
                :selected="contact.phones[0].ddi === ddi.number"
              )
        input-phone.flex-1(
          label="Número de telefone principal"
          v-model="contact.phones[0].number"
          :key="updatePhone"
        )
        div.flex
          f-tooltip(placement='top' v-if="hasAgile")
            .material-icons.notranslate.p-2.ml-2.animate-ping.text-brand-800.absolute.cursor-pointer(
              v-if="isOverlay && agileEvent && openedByContextValue"
              @click="openContextModal('phone')"
              class="transition-all ease-linear hover:text-brand-300"
            ) bolt
            .material-icons.notranslate.p-2.ml-2.cursor-pointer(
              v-if="isOverlay && agileEvent"
              @click="openContextModal('phone')"
              class="transition-all ease-linear hover:text-brand-300"
            ) bolt
            template(#popper)
              p Utilizar dados do contexto
      label.up-placeholder(v-if="contact.phones.length > 1") Outros números
      .input-icons(
        v-for="(number, index) in contact.phones.slice(1, contact.phones.length)",
        :key="`${index}-phone-number`"
        style="justify-content: flex-end; margin-bottom: 16px"
      )
        .flex.gap-4.w-full
          v-dropdown.w-14.h-full(placement="bottom")
            button(class="flex items-center flex-1 px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none group h-11 hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10")
              .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") +{{ number.ddi }}
              span(class="text-gray-400 material-icons notranslate group-hover:text-brand") keyboard_arrow_down
            template(#popper)
              ul(class="w-full divide-y")
                dropdown-item(
                  v-for="(ddi, index) in avaliableDdis.filter((ddi) => ddi.number.includes(ddiSearch))",
                  @click="number.ddi = ddi.number",
                  :label="`${ddi.flag} +${ddi.number}`",
                  :key="index",
                  :hide-icon="true"
                  :selected="number.ddi === ddi.number"
                )
          input-phone.flex-1(
            label="Número de telefone"
            :index="index + 1",
            v-model="number.number"
          )
        i.material-icons.notranslate.notranslate.closeicon-input(
          v-if="index !== -1",
          v-on:click="contact.phones.splice(index + 1, 1)",
          class="",
          style="margin-right: 14px"
        ) &#xe5cd

    .flex.w-full.mt-4.justify-end
      a.font-bold.font-inter.text-xs.text-center.text-brand.tracking-widest.w-auto(
        class="hover:text-opacity-60"
        role="button",
        v-on:click="appendNumberInput()",
      ) Adicionar número
    .flex.gap-4.mt-10
      v-button-secondary(@click="handleCancelButton") Cancelar
      v-button(@click="saveContact()", :disabled="creating") Salvar

      modal(
        @click="contextModalOpened = false"
        v-if="contextModalOpened == true"
      )
        modal-body.w-96
          template(v-slot:header)
            h4.text-2xl.text-gray-500.font-semibold.font-inter Informações do contexto
          template(v-slot:body)
            icon-input(
              icon="search" v-model="search" label="Titulo"
            )
            p.text-xs.text-zinc-400.text-left.w-full.mt-4 Selecione uma informação para inserirmos no campo de texto
            div.max-h-56.w-full.overflow-y-scroll.mt-2
              transition-group.w-full.flex.flex-col.gap-2(name="slide-fade" :duration="200" mode="out-in")
                .flex.border.w-full.p-2.rounded-md.cursor-pointer(
                  @click="selectContextInfo(key)"
                  class="transition-all ease-linear hover:border-brand-150"
                  :class="{ 'border-brand-500': dataArray[key][1].selected }"
                  v-for="key in Object.keys(filteredData)"
                  :key="key"
                )
                  span.font-plus.font-bold.break-words.max-w-full
                    | {{ filteredData[key].chave }}:
                    span.font-plus.font-semibold.break-words {{ filteredData[key].valor }}
                  .p-1.border.rounded-full.ml-auto
                    .p-1.rounded-full(
                      :class="{ 'bg-brand-500': dataArray[key][1].selected }"
                    )
              p.w-full.mt-3.text-center(
                v-if="Object.keys(filteredData).length < 1"
              ) Nenhuma informação encontrada
          template(v-slot:footer)
            .flex.gap-2
              v-button-secondary(
                @click="contextModalOpened = false"
              ) Cancelar
              v-button(
                :class="{ 'pointer-events-none opacity-50': !selectedData }"
                @click="confirmContextInfo"
              ) Confirmar
</template>

<script>
import { parsePhoneNumber } from '@/utils/google-libphonenumber.util';
import { Dropdown, Tooltip } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import cListService, { getCategoryList } from '@/services/contact-list.js';
import OrganizationInput from './Contact.DropdownInput';
import CategoryInput from './Category.DropdownInput';
import VuePhoneNumberInput from 'vue-phone-number-input';
import PhoneInput from './Contact.PhoneInput';
import VButton from '@/components/Buttons/v-button.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import SimpleInput from '@/components/Inputs/SimpleInput.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VHeading from '@/components/Text/v-heading.vue';
import '../../assets/css/vue-phone-number-input.css';
import IconInput from '@/components/Inputs/IconInput.vue';
import InputPhone from '@/components/Inputs/InputPhone.vue';
import eventBus from '@/main-event-bus';
import UploadImage from '@/components/Modals/UploadImage.vue';

export default {
  title: 'Opens - Adicionar contato',
  components: {
    Modal,
    VButton,
    VHeading,
    ModalBody,
    PhoneInput,
    SimpleInput,
    CategoryInput,
    VButtonSecondary,
    OrganizationInput,
    VuePhoneNumberInput,
    UploadImage,
    VDropdown: Dropdown,
    DropdownItem,
    FTooltip: Tooltip,
    IconInput,
    InputPhone,
  },
  props: {
    isOverlay: {
      type: Boolean,
      default: false,
    },
    openedByContext: {
      type: Boolean,
      default: false,
    },
    overlayContact: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showUploadImageModal: false,
      displayNewCategoryModal: false,
      newCategoryName: '',
      showOrgList: false,
      showTagList: false,
      contact: {
        name: '',
        cpf: '',
        phones: [{ number: '', ddi: '55' }],
        emails: [{ address: '' }],
      },
      updatePhone: 0,
      selectedOrgName: '',
      selectedCategoryName: '',
      organizationList: [],
      index: null,
      categories: [],
      file: null,
      payload: null,
      uploaded: false,
      orgKey: 0,
      creating: false,
      orgError: false,
      clist: process.env.VUE_APP_CLIST,
      contextModalOpened: false,
      agileEvent: {},
      selectedData: null,
      selectedInfo: '',
      dataArray: [],
      search: '',
      hasAgile: false,
      avaliableDdis: [{ number: '55', flag: 'BR' }],
      ddiSearch: '',
    };
  },
  computed: {
    modal() {
      return this.$store.getters['modal/activeModal'];
    },
    computedCategories() {
      return this.categories.filter((category) =>
        category.name.toLowerCase().startsWith(this.selectedCategoryName?.toLowerCase()),
      );
    },
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    filteredData() {
      const filtered = this.dataArray.filter(([key, value]) =>
        this.dataArray[key][1].chave.toLowerCase().startsWith(this.search.toLowerCase()),
      );
      return Object.fromEntries(filtered);
    },
  },
  watch: {
    async modal(value) {
      if (!value) {
        this.organizationList = await cListService.getOrganizationList();
      }
    },
    contextModalOpened(val) {
      if (!val) {
        this.selectedInfo = '';
        this.search = '';
        this.selectedData = null;
        this.dataArray.forEach((item) => {
          item[1].selected = false;
        });
      }
    },
    'contact.cpf'(val) {
      this.$nextTick(() => {
        this.contact.cpf = this.formatCpfNumber(val);
      });
    },
    selectedOrgName(val) {
      if (!val) {
        this.contact.organizationId = null;
      }
    },
    selectedCategoryName(val) {
      if (!val) {
        this.contact.categoryId = null;
      }
    },
  },
  methods: {
    async handleApplyImage(data) {
      this.uploaded = true;
      this.file = data.blobImage;
      this.$refs['imgBox'].src = URL.createObjectURL(data.blobImage);
      this.$refs['imgBox'].style.display = 'flex';
      this.showUploadImageModal = false;
    },
    async fetchCountries() {
      try {
        const res = await this.$http.get('https://restcountries.com/v2/all');

        const avaliableDdis = res.data.map((country) => {
          return {
            number: country.callingCodes[0],
            flag: country.alpha2Code,
          };
        });

        this.avaliableDdis = avaliableDdis;
      } catch (e) {
        this.$toast.error("Erro ao buscar DDI's");
      }
    },
    formatCpfNumber(value) {
      if (!value) return '';
      let cpf = value?.replace(/\D/g, '')?.slice(0, 11);
      if (!cpf?.length) return '';
      let formattedCPF = '';

      for (let i = 0; i < cpf.length; i++) {
        if (i === 3 || i === 6) {
          formattedCPF += '.';
        } else if (i === 9) {
          formattedCPF += '-';
        }
        formattedCPF += cpf[i];
      }

      return formattedCPF;
    },
    async confirmContextInfo() {
      this.contextModalOpened = false;
      if (this.selectedInfo === 'email') {
        this.contact.emails[0].address = this.selectedData.valor + '';
        return;
      }
      if (this.selectedInfo === 'phone' && this.hasOnlyNumbers(this.selectedData.valor + '')) {
        this.contact.phones[0].number = this.selectedData.valor + '';
        this.updatePhone++;
        return;
      }
      if (this.selectedInfo === 'cpf') {
        this.contact.cpf = this.selectedData.valor + '';
      }
      this.contact[this.selectedInfo] = this.selectedData.valor + '';
    },
    selectContextInfo(key) {
      this.dataArray.forEach((item) => {
        item[1].selected = false;
      });
      this.selectedData = this.dataArray[key][1];
      this.dataArray[key][1].selected = true;
    },
    desestruturarObjeto(objeto, objectFiltered = []) {
      if (!objeto) {
        return objectFiltered;
      }
      for (const [chave, valor] of Object.entries(objeto)) {
        if (typeof valor === 'object') {
          this.desestruturarObjeto(valor, objectFiltered);
        } else {
          objectFiltered.push({ chave: chave, valor: valor });
        }
      }
      return objectFiltered;
    },
    hasOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    async initData() {
      try {
        const res = await this.$http.get(
          `${this.requestLinks.rhistory}/event/call/${this.call.linkedid || this.call.uniqueid}`,
        );
        res.data.forEach((e) => {
          if (e.eventType === 'agile') {
            return (this.hasAgile = true);
          }
        });
        if (!this.hasAgile) return;
        this.agileEvent = res.data.filter((e) => {
          if (e.eventType === 'agile') return e;
        });
      } catch (e) {
        this.$log.info(e);
      } finally {
        this.$log.info('CONNECT TO RSHIP SOCKET');
      }
    },
    openContextModal(inputHandle) {
      this.openedByContextValue = false;
      this.selectedInfo = inputHandle;
      this.contextModalOpened = true;
    },
    handleCancelButton() {
      if (this.isOverlay) {
        this.$emit('close-overlay');
      } else {
        this.$router.push({ name: 'Contatos' });
      }
    },
    addTag(name) {
      const category = {
        name: name,
      };
      this.categories.push(category);
      this.contact.category = category;
    },
    addNewOrganization() {
      this.selectedOrgName = '';
      this.$store.commit('modal/setActiveModal', 'CreateOrganization');
    },
    update(payload) {
      this.payload = payload;
    },
    appendEmailInput() {
      this.contact.emails.push({ address: '' });
    },
    async addNewCategory() {
      try {
        this.selectedCategoryName = '';
        const category = await cListService.createCategory({
          name: this.newCategoryName,
          companyId: this.user.company_id,
        });
        this.categories = await getCategoryList();
        this.$toast.success('Categoria criada com sucesso!');
        this.selectedCategoryName = category.name;
        this.contact.categoryId = category.id;
        this.displayNewCategoryModal = false;
      } catch (error) {
        this.$toast.error('Opa, não conseguimos criar essa categoria...');
      }
    },
    selectCategory(category) {
      this.selectedCategoryName = category.name;
      this.contact.categoryId = category.id;
      this.showTagList = false;
    },
    selectOrganization(org) {
      this.selectedOrgName = org.name;
      this.contact.organizationId = org.id;
      this.orgKey++;
      this.showOrgList = false;
    },
    async isPhoneNumberValid(number) {
      try {
        if (number.length > 7) return true;
        this.$store.commit('SET_MODAL', { name: 'InvalidInfo' });
        return false;
      } catch (e) {
        Promise.reject(e);
      }
    },
    async isValidInputs() {
      try {
        if (this.selectedOrgName && !this.contact.organizationId) {
          throw new Error('Por favor selecione a organização através do menu interativo');
        }
        if (!this.contact.name) {
          throw new Error('Todo contato deve ter um nome!');
        }
        if (this.selectedCategoryName && !this.contact.categoryId) {
          throw new Error('Por favor selecione uma categoria através do menu interativo');
        }

        return true;
      } catch (error) {
        this.$toast.error(error.message);
        return false;
      }
    },
    async saveContact() {
      const isValidInputs = await this.isValidInputs();
      if (!isValidInputs) return;

      try {
        this.creating = true;
        if (this.contact.phones[0].number) {
          for (const phone of this.contact.phones) {
            if (phone.ddi == '55') {
              const { ddiParsed, isValidNumber, phoneNumberParsed } = await parsePhoneNumber(phone.number, phone.ddi);

              if (!isValidNumber) {
                return this.$toast.error('Número de telefone inválido! Verifique também o digito 9');
              }

              phone.number = phoneNumberParsed;
              phone.ddi = ddiParsed;
            } else {
              phone.number = phone.number.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '');
            }
            phone.country = this.avaliableDdis.filter((ddi) => ddi.number == phone.ddi)[0].flag;
            phone.companyId = this.user.company_id;
          }
        } else {
          delete this.contact.phones;
        }

        if (this.contact?.cpf) {
          this.contact.cpf = this.contact?.cpf?.replace(/\D/g, '') || null;
          if (this.contact?.cpf?.length < 11) {
            return this.$toast.error('CPF inválido');
          }
        } else {
          delete this.contact.cpf;
        }

        const data = await cListService.createContact(this.contact);

        if (this.file) {
          let formData = new FormData();
          formData.append('image', this.file);
          await this.$http.post(`${this.requestLinks.clist}/contact/avatar/${data.id}`, formData);
        }

        this.$toast.success('Contato salvo com sucesso!');
        if (this.isOverlay) {
          this.$emit('contact-update', data);
          this.$emit('close-overlay');
        } else {
          this.$router.push({ name: 'Contatos' });
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error?.response?.data?.message || 'Opa, não conseguimos salvar esse contato...');
      } finally {
        this.creating = false;
      }
    },
    appendNumberInput() {
      this.contact.phones.push({ number: '', ddi: '55' });
    },
    uploadImage(e) {
      this.uploaded = true;
      this.file = e.currentTarget.files[0];
      this.$refs['imgBox'].src = URL.createObjectURL(e.currentTarget.files[0]);
      this.$refs['imgBox'].style.display = 'flex';
    },
    watchOrganization() {
      this.showOrgList = true;
    },
    startWithOverlayContactData() {
      this.contact.name = this.overlayContact?.name;
      if (this.overlayContact?.emails?.length > 0) {
        this.contact.emails = this.overlayContact.emails?.map((email) => ({ address: email?.email }));
      }
      if (this.overlayContact?.phones?.length > 0) {
        this.contact.phones = this.overlayContact.phones.map((phone) => {
          const { brazilNumber, ddiParsed, isValidBrazilNumber, phoneNumberParsed } = parsePhoneNumber(phone.number);
          if (isValidBrazilNumber) {
            return { number: brazilNumber, ddi: '55' };
          }

          return { number: phoneNumberParsed, ddi: ddiParsed };
        });
      }
    },
  },
  async created() {
    await this.fetchCountries();
    this.organizationList = await cListService.getOrganizationList();
    this.categories = await getCategoryList();
    this.user.endpoint == this.call?.from
      ? (this.contact.phones[0].number = this.call.to)
      : (this.contact.phones[0].number = this.call?.from);
    this.openedByContextValue = this.openedByContext;
    if (this.isOverlay) {
      if (this?.overlayContact) {
        this.startWithOverlayContactData();
      } else {
        await this.initData();
        if (!this.hasAgile) return;
        this.dataArray = Object.entries(
          this.desestruturarObjeto(JSON.parse(`${this.agileEvent[0].eventBody.replace(/'/g, '"')}`)),
        );
      }
    }
  },
};
</script>
