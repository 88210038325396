<template lang="pug">
div(style="display: contents")
  tr.history-table-row.text-inside-brand(v-if="loaded")
    td.callback-table-starter(scope="row")
      .history-type-icon.flex.items-center.justify-start
        .flex
          img.w-8.mr-2(v-if="provider === 'whatsapp'" src="@/assets/images/whatsapplogo.png")
          img.w-8.mr-4(v-else-if="provider === 'yunique'" src="@/assets/images/opens-app-logo.png")
          img.w-6.mr-4(v-else-if="provider === 'outlook'" src="@/assets/images/outlooklogo.png")
          img.w-6.mr-4(v-else-if="provider === 'gmail'" src="@/assets/images/provider-gmail-logo.png")
          img.w-8.mr-2(v-else-if="provider === 'messenger'" src="@/assets/images/messenger-logo.png")
          img.w-6.mr-4(v-else-if="provider === 'direct'" src="@/assets/images/direct-logo.png")

          i.text-white.rounded-full.cursor-default.small.material-icons.notranslate.text-base.w-6.grid.place-items-center(
            :class="bgClass",
            class="dark:text-black"
          ) chat
          span.history-type-text.text-marker {{ textForCallType[data.status] }}
    td.callback-callto-content
      span.history-type-text.text-marker {{ $moment(data.createdAt).format('DD/MM/yyyy - HH:mm') }}
    td.callback-callto-content.align-middle
      .callback-callto-content-aligner
        .callback-callto-user-img(v-if="failedImg") {{ contact?.name?.[0]?.toUpperCase() || 'D' }}
        img.callback-callto-user-img(
          :src="`${requestLinks.clist}/uploads/${contact?.id}.jpg`"
          v-if="!failedImg"
          @error="failedImg = true"
        )
        .callback-callto-info-aligner
          p.callback-callto-name-text.text-marker {{ contact?.name || 'Desconhecido' }}
          p.callback-callto-number-text(v-if="contact?.organization") {{ contact?.organization?.name }}
          p.callback-callto-number-text(v-else-if="chatProviders.includes(provider) && contact?.number") {{ formatInputValue(contact?.number) }}
          p.callback-callto-number-text(v-else-if="contact?.emails?.length") {{ contact?.emails[0].address }}
    td.callback-callto-content
      .history-right-info.w-fit
        .tag {{ data.tags && data.tags.length ? data.tags[0].text : 'Sem Tag' }}
        .aditional-tag-margin.tag(
          v-if="data.tags && data.tags?.length > 1",
          @mouseover="showAllTags",
          @mouseleave="hideAllTags"
        ) +{{ data.tags?.length - 1 }}
      .tags-component-aligner
        .tags-component-content(
          v-if="data.tags?.length",
          ref="tags-component"
        )
          p.tags-text Tags
          .tags-box
            .tag.multiple-tags-margin(
              v-for="(data, index) in data.tags",
              :key="index"
            )
              | {{ data.text }}
    td.callback-callto-content
      span.history-type-text.text-marker Duração {{ $moment.utc(data.attendanceTime * 1000).format('HH:mm:ss') }}
    .text-black.material-icons.notranslate.mt-6(
        v-tooltip="'Expandir atividade'"
        @click="isOpened = !isOpened",
        :style="isOpened ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'"
      ) keyboard_arrow_down
  tr(v-if="isOpened && loaded")
    chat-event-detail(:chat-data="{ room: data }" :openedByCallback="true")
  tr.history-table-row(v-if="!loaded")
    td.align-middle.px-4(v-for="n in 5" :key="n")
      content-placeholders(:rounded="true").w-full
        content-placeholders-img.w-full.h-14
</template>

<script>
import ChatEventDetail from '@/components/ChatEventDetail.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import rhService from '@/services/rship';

export default {
  props: ['data'],
  mixins: [UserOrContactMixin],
  components: {
    ChatEventDetail,
  },
  data() {
    return {
      loaded: false,
      textForCallType: {
        terminated: 'Conversa finalizada pelo operador',
        completed: 'Conversa finalizada',
        expired: 'Conversa finalizada pelo sistema',
        established: 'Conversa em andamento',
        routing: 'Conversa em espera',
        pending: 'Conversa pendente',
      },
      hideTagsTimeout: null,
      showTagsTimeout: null,
      failedImg: false,
      isOpened: false,
      firstTagText: '',
    };
  },
  computed: {
    provider() {
      const clientIdentifier = this.data?.createdBy;
      const member = this.data?.members?.find((member) => member.recipientId === clientIdentifier);
      return member?.provider;
    },
    chatProviders() {
      return process.env.VUE_APP_CHAT_PROVIDERS;
    },
    bgClass() {
      const colors = {
        terminated: 'bg-green-400 dark:invert',
        completed: 'bg-green-400 dark:invert',
        expired: 'bg-red-400 dark:invert',
        established: 'bg-yellow-400 dark:invert',
        routing: 'bg-yellow-400 dark:invert',
        pending: 'bg-yellow-400 dark:invert',
      };
      return colors[this.data.status];
    },
  },
  methods: {
    showAllTags() {
      if (!this.$refs['tags-component']) return;
      clearTimeout(this.hideTagsTimeout);
      this.$refs['tags-component'].style.display = 'flex';
      this.showTagsTimeout = setTimeout(() => (this.$refs['tags-component'].style.opacity = 1), 1);
    },
    hideAllTags() {
      if (!this.$refs['tags-component']) return;
      clearTimeout(this.showTagsTimeout);
      this.$refs['tags-component'].style.opacity = 0;
      this.hideTagsTimeout = setTimeout(() => (this.$refs['tags-component'].style.display = 'none'), 300);
    },
    async getContactById() {
      try {
        await this.setContactOrOperator({
          id: this.data.createdBy,
        });
      } catch (error) {
        console.error('error', error);
      }
    },

    formatInputValue(number) {
      try {
        if (number.length > 4) {
          return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(number, 'BR'), this.PNF.INTERNATIONAL);
        } else if (number.length === 4) {
          return 'SIP ' + number;
        }
        return number;
      } catch (error) {
        return number;
      }
    },
  },
  async created() {
    try {
      this.data.members.forEach((member) => {
        if (member.recipientId == this.data.createdBy) {
          if (member.provider == 'yunique') {
            return (this.contact.phoneNumber = 'Widget');
          } else {
            return (this.contact.phoneNumber = member.channel);
          }
        }
      });

      if (this.data.tags?.length) {
        const tagIds = this.data.tags.map((tag) => tag.tagId).join(',');
        const tags = await rhService.getTags(this.user.company_id, { tagIds });
        this.data.tags = tags;
      }

      await this.getContactById();
    } catch (error) {
      console.error('error', error);
    } finally {
      this.loaded = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.material-icons {
  color: #fafafa;
}
</style>
<style>
.relative {
  position: relative;
}
.history-table-tags-content {
  display: flex;
  align-items: center;
  height: 80px;
  flex-wrap: nowrap;
  min-width: max-content;
  flex-direction: row;
}
</style>
