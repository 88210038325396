<template lang="pug">
table.callback-table
  thead.callback-thead
    tr
      th.callback-table-starter(role='columnheader' scope='col') Tipo de atividade
      th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role='columnheader' scope='col') Data
      th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role='columnheader' scope='col') Contato
      th.mdc-data-table__header-cell(role='columnheader' scope='col' v-if='calls?.length > 0 || emails?.length > 0') Fila
      th.mdc-data-table__header-cell(role='columnheader' scope='col' v-if='chats?.length > 0') Tag
      th.mdc-data-table__header-cell(role='columnheader' scope='col') Duração

  transition-group(tag='tbody' name='slide-fade' :duration='200' mode='out-in' class='mdc-data-table__content')
    activity-row(
      class='text-inside-brand'
      v-if='calls.length > 0'
      v-for='(call, index) in calls'
      :key='call.linkedId'
      :call='call'
      :index='index'
      @click.native='sendToDetail(call)'
    )

    chat-row(
      v-for='data in chats'
      v-if='chats.length > 0'
      :key='data.id'
      :data='data'
    )

    chat-row(
      v-for='data in emails'
      v-if='emails.length > 0'
      :key='data.id'
      :data='data'
    )

  call-details-overlay(
    v-if='showCallDetailsModal'
    @close='showCallDetailsModal = false'
    :callLinkedId='callLinkedId'
  )
</template>

<script>
import ActivityRow from './Callbacks.HistoryActivityRow';
import ChatRow from './Callbacks.HistoryChatRow.vue';
import callDetailsOverlay from '@/components/Overlay/call-details.overlay.vue';

export default {
  props: ['calls', 'chats', 'emails'],
  components: {
    ActivityRow,
    ChatRow,
    callDetailsOverlay,
  },
  data() {
    return {
      showCallDetailsModal: false,
      callLinkedId: '',
    };
  },
  methods: {
    sendToDetail(call) {
      this.callLinkedId = call.linkedId;
      this.showCallDetailsModal = true;
    },
  },
};
</script>

<style>
.history-type-icon {
  display: flex;
}
.history-type-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 8px;
}
.history-table-row {
  padding: 16px;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  height: 80px;
}
.history-table-row:hover {
  background-color: #f8f8f8;
}
.md-table-cell-container {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}
</style>
